import React, { useEffect } from "react"
import { useDispatch } from 'react-redux';
import { useWebsocketChannel } from 'modules/websocket'
import useAddFlashMessage from 'reacticoon-plugin-flash-messages/useAddFlashMessage'
import notificationLocker from "modules/notification/notificationLocker"
import useRouting from "reacticoon/routing/useRouting"
import { dispatchEvent } from "modules/notification/event"
import { NotificationType, addNotification } from "modules/notification"
import { displayNotification, askNotificationPermission } from "modules/notification/native"
import { formatFeedMessage } from "../ticket/formatTicket";
import TicketWatcherFlashMessageNotification from "../notifications/TicketWatcherFlashMessageNotification"


function NotificationsWebSocket() {
  const { addInfoFlashMessage } = useAddFlashMessage()
	const { lastMessage } = useWebsocketChannel('NOTIFICATION')
	const { redirectTo } = useRouting()
	const dispatch = useDispatch()

	useEffect(() => {
		askNotificationPermission(
			() => { // success

			},
			() => { // failure

			}
		)
	}, [])

	useEffect(() => {
		const notification = lastMessage?.payload
		if (notification) {
			switch (notification.type) {
				case 'TICKET_MESSAGE_RECEIVED':
					const lastFeed = formatFeedMessage(notification.data.lastFeed)

					const locked = notificationLocker.isLocked('TICKET_MESSAGE_RECEIVED', lastFeed.ticketId)
					const hasFocus = document.hasFocus()
					
					// if not focused, notify
					if (locked) {
						console.info(`notification locked`)
					}

					if (!locked) {
						addInfoFlashMessage({
							id: `TICKET_MESSAGE_RECEIVED_${lastFeed.id}`,
							duration: 5000, // 5s
							notificationType: NotificationType.TICKET_MESSAGE_RECEIVED,
							text: (
								<TicketWatcherFlashMessageNotification lastFeed={lastFeed} />
							)
						})
					}

					if (!locked) {
						dispatch(addNotification({
							id: `TICKET_MESSAGE_RECEIVED_${lastFeed.id}`,
							notificationType: NotificationType.TICKET_MESSAGE_RECEIVED,
							duration: 2000,
							data: {
								...notification.data
							},
						}))
					}

					// locked: we are on the page, but we do not focus the window, add native notification.
					if (!locked || !hasFocus) {
						displayNotification({
							title: `#${lastFeed.ticketDisplayId} - ${lastFeed.authorDisplayName}`,
							body: lastFeed.resume,
							// merge notifications if multiple notifs for the same message.
							tag: `TICKET_MESSAGE_RECEIVED_${lastFeed.ticketId}`,
							onClick: () => {
								redirectTo('TICKET', { displayId: lastFeed.ticketDisplayId })
							}
						})
					}
					break

				default:
					console.warn(`notification not handled ${notification.type}`)
			}

			dispatchEvent(notification.type, { notification })
		}
	}, [lastMessage])

	
	return (
		<>
		</>
	)
}

export default NotificationsWebSocket
