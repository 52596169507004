import { getFromStorage, saveToStorage, removeFromStorage } from 'reacticoon/storage'

export const SESSION_KEY = '__session'

export const getJwtToken = () => {
  const jwt = getFromStorage(SESSION_KEY)
  return jwt
}

export const getSession = () => {
  const jwt = getFromStorage(SESSION_KEY)
  let session
  try {
    if (jwt) {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      session = JSON.parse(window.atob(base64))
    }
  } catch (error) {
    console.log(error)
  }
  return session
}

export const saveSession = (jwt) => {
  saveToStorage(SESSION_KEY, jwt)
}

export const removeAuth = () => {
  removeFromStorage(SESSION_KEY)
}
