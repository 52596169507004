import { createModule } from 'reacticoon/module'

import * as selectors from './selectors'

const AuthModule = createModule('App::AuthModule', {
  noReducer: true,
  selectors,
})

export default AuthModule
