import { __DEV__ } from 'reacticoon/environment' // TODO: remove
import reacticoonLogger from 'reacticoon-plugins/reacticoon-plugin-logger/src'
import reacticoonSentry from 'reacticoon-plugins/reacticoon-plugin-sentry/src'
import reacticoonMaterialUI from 'reacticoon-plugins/reacticoon-plugin-material-ui/src'
import reacticoonForm from 'reacticoon-plugins/reacticoon-plugin-form/src'
import { reacticoonValidator } from 'reacticoon-plugins/reacticoon-plugin-validation/src'
import reacticoonFlashMessages from 'reacticoon-plugins/reacticoon-plugin-flash-messages/src'

import theme from './theme'

export default [
  __DEV__ && {
    plugin: require('reacticoon-plugins/reacticoon-plugin-mock-api/src').default,
    config: {
      enabled: true,
    },
  },
  __DEV__ && {
    plugin: require('reacticoon-plugins/reacticoon-plugin-example/src').default,
    config: {
      toto: 42,
    },
  },
  __DEV__ && {
    plugin: require('reacticoon-plugins/reacticoon-plugin-ci/src').default,
  },
  __DEV__ && {
    plugin: require('reacticoon-plugins/reacticoon-plugin-lighthouse/src').default,
    options: {},
  },
  __DEV__ && {
    plugin: require('reacticoon-plugins/reacticoon-plugin-bundle-stats/src').default,
    options: {},
  },
  {
    plugin: reacticoonMaterialUI,
    config: {
      theme,
    },
  },
  {
    plugin: reacticoonFlashMessages,
    config: {
      types: ['TEST'],
    },
  },
  {
    plugin: reacticoonLogger,
    config: {},
  },
  {
    plugin: reacticoonSentry,
    config: {
      // TODO:
      sentryUrl: '',

      displayReportDialog: true,

      // TODO:
      appVersion: '',
    },
  },
  {
    plugin: reacticoonForm,
    config: {
      // the validator to use with our form
      validator: reacticoonValidator,
      forms: [
      ],
    },
  },
]
