import React, { Component } from 'react'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from "moment";

import { connect } from 'reacticoon/view'
import { isPendingI18nPhrases } from 'reacticoon/i18n'

import AuthContainer from 'modules/auth/container'
import ReacticoonMaterialUIContent from 'reacticoon-plugin-material-ui/views/Content'
import DragAndDropContainer from 'reacticoon-plugin-dnd/view/DragAndDropContainer'
import MajView from 'components/MajView'
import { ModalProvider } from "../components/modal/modalContext";
import { WebsocketProvider } from "../modules/websocket"
import UserMeContext from 'components/UserMeContext'

import { getQueryParam } from 'reacticoon/routing'
import { saveToStorage } from 'reacticoon/storage'
import { SESSION_KEY } from "../modules/auth/config"
import NotificationsWebSocket from "components/Page/NotificationsWebSocket"
import FlashMessages from '../components/Page/FlashMessages'
import { CadFactionProvider } from '../components/CadFactionContext';
import PublicVariablesProvider from 'modules/publicVariables/PublicVariablesProvider'


/**
 * The content of the app will have the `router` as children.
 * Use it to englobe the router with providers in order to not re-render those providers when the
 * route change
 */
class Content extends Component {

  constructor(props) {
    super(props)

    // when opening tase in game, the url will contains the 'token' authorization: the game server
    // ask the API for a token for the current player, and open tase with this token.
    // We must save this storage, to be used for our API calls.
    const token = getQueryParam('token')
    if (token) {
      saveToStorage(SESSION_KEY, `Bearer ${token}`)
    }
  
    this.setUserMe = (userMe) => {
      this.setState(state => ({
        userMe,
      }));
    };

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      userMe: null,
      setUserMe: this.setUserMe
    };
  }

  render() {
    const { isPendingI18nPhrases, children } = this.props

    return (
      <React.Fragment>
        <PublicVariablesProvider>
          <CadFactionProvider>
            <UserMeContext.Provider value={this.state}>
              <MajView>
                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                  <WebsocketProvider>
                    <>

                      <AuthContainer>
                        <DragAndDropContainer>
                          <ReacticoonMaterialUIContent>
                            <ModalProvider>
                              <>
                                {isPendingI18nPhrases
                                  ? // TODO: display loading
                                  null
                                  : children}

                                <FlashMessages />
                              </>
                            </ModalProvider>

                          </ReacticoonMaterialUIContent>
                        </DragAndDropContainer>
                      </AuthContainer>

                      <NotificationsWebSocket />
                    </>
                  </WebsocketProvider>
                </MuiPickersUtilsProvider>
              </MajView>
            </UserMeContext.Provider>
          </CadFactionProvider>
        </PublicVariablesProvider>


        {/* Cf ModalProvided Modal.js */}
        <div id="modal-root" />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isPendingI18nPhrases: isPendingI18nPhrases(state),
  }
}

export default connect(mapStateToProps, {})(Content)
