import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import WarningOutline from '@material-ui/icons/Warning'
import InfoOutline from '@material-ui/icons/Info'
import ErrorOutline from '@material-ui/icons/Error'
import TypographyCustom from 'components/TypographyCustom'

const MessageBlockSdkStyles = (theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '82vw'
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  variant_contained: {}, // nothing
  variant_text: {
    background: 'none!important',
    border: 'none!important',
    '& *': {
      color: 'inherit',
    },
  },
  centerContent: {
    justifyContent: 'center'
  }
})

const MessageBlockSdkA = ({
  classes,
  className,
  icon,
  variant = 'contained',
  text,
  centerContent,
  ...otherProps
}) => (
  <div
    className={clsx(
      classes.root,
      className,
      clsx({
        [classes.variant_contained]: variant === 'contained',
        [classes.variant_text]: variant === 'text',
      })
    )}
    {...otherProps}
  >
    <div
      className={clsx(classes.container, {
        [classes.centerContent]: centerContent,
      })}
    >
      {icon && <div className={classes.icon}>{icon}</div>}
      {text}
    </div>
  </div>
)

MessageBlockSdkA.propTypes = {
  // icon element
  icon: PropTypes.element,

  // text to display, can be a string or an element
  // e.g.: <Typography />skipColor
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

  variant: PropTypes.string,
}

const MessageBlockSdk = withStyles(MessageBlockSdkStyles)(MessageBlockSdkA)

//
//
//

const generateStyle = (color) => ({
  color,
  border: `1px solid ${color}`,
  padding: '4px 2px',
  borderRadius: '4px',
})

const styles = (theme) => ({
  errorRoot: {
    ...generateStyle(theme.status.error),
  },
  warningRoot: {
    ...generateStyle(theme.status.warning),
  },
  infoRoot: {
    ...generateStyle(theme.status.info),
  },
})

const Type = {
  INFO: 'TYPE::INFO',
  ERROR: 'TYPE::ERROR',
  WARNING: 'TYPE::WARNING',
}

/**
 * error will be displayed with the color defined in theme: theme.status.error
 * be sure to set it properly in your config theme file
 */
const MessageBlock = ({ classes, type, text, ...otherProps }) => {
  switch (type) {
    case Type.ERROR:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.errorRoot,
          }}
          icon={<ErrorOutline />}
          text={
            <TypographyCustom type="144" skipColor>
              {text}
            </TypographyCustom>
          }
          {...otherProps}
        />
      )

    case Type.WARNING:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.warningRoot,
          }}
          icon={<WarningOutline />}
          text={
            <TypographyCustom type="144" skipColor>
              {text}
            </TypographyCustom>
          }
          {...otherProps}
        />
      )

    case Type.INFO:
    default:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.infoRoot,
          }}
          icon={<InfoOutline />}
          text={
            <TypographyCustom type="144" skipColor>
              {text}
            </TypographyCustom>
          }
          {...otherProps}
        />
      )
  }
}

MessageBlock.defaultProps = {
  type: Type.INFO,
}

MessageBlock.propTypes = {
  // type of message block to display
  type: PropTypes.string,

  // text to display
  text: PropTypes.string,

  variant: PropTypes.string,
}

const MessageBlockComponent = withStyles(styles)(MessageBlock)

MessageBlockComponent.Type = Type

const errorStyles = (theme) => ({
  errorRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

const MessageBlockError = withStyles(errorStyles)(({ error, text, classes, ...otherProps }) => {
  if (!error && !text) {
    return null
  }

  error = error || text
  let finalMessage
  if (!isNil(error)) {
    if (!isString(error)) {
      finalMessage = isEmpty(get(error, 'message'))
        ? get(error, 'error.message', null)
        : error.message
    } else {
      finalMessage = error || 'Erreur inconnue'
    }
  }

  const finalText = (
    <div className={clsx(classes.errorRoot)}>
      <span>{finalMessage}</span>
    </div>
  )

  return (
    <MessageBlockComponent text={finalText} type={Type.ERROR} classes={classes} {...otherProps} />
  )
})

MessageBlockError.displayName = "MessageBlockError"

MessageBlockComponent.Error = MessageBlockError

MessageBlockComponent.Info = ({ info, text, ...otherProps }) =>
  info || text ? (
    <MessageBlockComponent text={info || text} type={Type.INFO} {...otherProps} />
  ) : null

MessageBlockComponent.Warning = ({ warning, text, ...otherProps }) =>
  warning || text ? (
    <MessageBlockComponent text={warning || text} type={Type.WARNING} {...otherProps} />
  ) : null

export default MessageBlockComponent
