import React from 'react'
import SecurityManager from 'modules/security/SecurityManager'
import clsx from 'clsx'
import { Link } from 'reacticoon/routing'
import classNames from 'classnames'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import TypographyCustom from 'components/TypographyCustom'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ButtonLink from 'components/ButtonLink'
import LogoIcon from 'components/Logo'
import PermissionView from 'components/PermissionView'
import WebsocketLoading from 'components/WebsocketLoading'
import Search from './Search'
import NotificationsMenu from "./NotificationsMenu"
import { useWebsocketChannel } from 'modules/websocket'
import Badge from '@material-ui/core/Badge'
import PeopleIcon from '@material-ui/icons/People'

function PlayerCount() {
  const { lastMessage: playerCountMessage } = useWebsocketChannel('PLAYER_COUNT')

  return (
    <Link to="RCON">
      <IconButton>
        <Badge badgeContent={playerCountMessage?.payload?.count || `0`} color="default" max={10000} >
          <PeopleIcon />
        </Badge>
      </IconButton>
    </Link>
  )
}

const styles = (theme) => ({
  title: {
    // flex: 1,
    display: "flex",
    marginLeft: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      marginLeft: 0
    },
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
  logo: {
    marginLeft: theme.spacing(2),
  },
  menuButtonHidden: {
    display: 'none',
  },
  btn: {
    color: 'white',
    ...theme.style.focusPrimary,
  },
  loggedContainer: {
    display: 'flex',
    alignItems: "center",
    flex: 1,
  },
  searchContainer: {
    marginLeft: 'auto',
    // flex: 1,
    // maxWidth: 480,
    // margin: 'auto',
  },
  right: {
    display: 'flex',
    marginLeft: 'auto',
  },
})

//
//
//

const Logged = ({ me, isMobile, route, classes }) => (
  <div className={classes.loggedContainer}>
    <PermissionView permission="api.general.canPerformQuery">
      <div className={classes.searchContainer}>
        <Search classes={{ search: classes.search }} route={route} />
      </div>
    </PermissionView>
    <div className={clsx(classes.right, 'u-flexCenter u-sizeFullHeight')}>

      {SecurityManager.hasPermission("api.playerList") && (
        <PlayerCount />
      )}

      {SecurityManager.hasPermission("api.ticket") && (
        <NotificationsMenu me={me} />
      )}

      <ButtonLink to="USER_SETTINGS" className={classes.btn} variant="text">
        {!isMobile && (
          <React.Fragment>
            {me.gamePlayer && (
              <span>
                {me.gamePlayer.fullName}
                &nbsp;-&nbsp;
              </span>
            )}
            {me.username}&nbsp;&nbsp;
          </React.Fragment>
        )}
        <AccountCircle />
      </ButtonLink>
    </div>
  </div>
)

//
//
//

const useWebsocketLoadingClasses = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
  },
}))

/**
 *
 */
const Header = ({ isLoggedIn, isMobile, title, classes, sidebarIsOpen, handleDrawerOpen, me, route }) => {
  const websocketLoadingClasses = useWebsocketLoadingClasses()
  return (
    <React.Fragment>
      {!sidebarIsOpen && (
        <React.Fragment>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, sidebarIsOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          {!isMobile && (
            <Link to="DASHBOARD">
              <LogoIcon width={'auto'} height={42} fill="white" className={classes.logo} />
            </Link>
          )}
        </React.Fragment>
      )}

      <TypographyCustom type="184" className={classes.title} skipColor>
        {title}

        {isLoggedIn && <WebsocketLoading classes={websocketLoadingClasses} />}
      </TypographyCustom>

      {isLoggedIn ? <Logged me={me} classes={classes} isMobile={isMobile} route={route} /> : null}
    </React.Fragment>
  )
}

export default withStyles(styles)(Header)
