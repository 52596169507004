import defaultEnv from './environment'

export default ({ getProcessEnv }) => ({
  ...defaultEnv,

  IS_DEV: true,

  HOSTNAME: 'localhost',

  API_URL: getProcessEnv('API_URL'),
  WEBSOCKET_URL: getProcessEnv('WEBSOCKET_URL'),
})
