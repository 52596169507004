import isFunction from 'lodash/isFunction'
import cloneDeep from 'lodash/cloneDeep'
import { createApiCallAction } from 'reacticoon/action'
import { __DEV__ } from 'reacticoon/environment'

/**
 *
 */
export const runCommand = createApiCallAction(
  'App::CommandModule::command',
  (id, command, options) => ({
    endpoint: `/commands/${command}`,
    type: 'POST',
    query: {
      ...options.queryParams,
    },
    body: {
      id,
      command: command,
      payload: options.payload,
    },
    ...(!__DEV__
      ? {}
      : !options.devResponse
      ? {}
      : {
          response: cloneDeep(isFunction(options.devResponse) ? options.devResponse() : options.devResponse),
        }),
  }),
  (id, command, options) => ({
    id,
    command,
    _data: options.data,
    onSuccess: options.onSuccess,
    onFailure: options.onFailure,
    payload: options.payload,
    keepDataOnRequest: options.keepDataOnRequest,
    paginateCommand: options.paginateCommand,
  })
)

export const resetRequestData = runCommand.resetRequestData((id, command, where) => ({
  id,
  command,
  where
}))

export const setData = runCommand.setData((id, command, data) => ({
  id,
  command,
  data
}))
