import React, { useContext, useMemo, useDebugValue } from 'react'

import isEmpty from 'lodash/isEmpty'
// import useMountEffect from 'reacticoon/view/hook/useMountEffect'
import SecurityManager from 'modules/security/SecurityManager'
import useStorageState from 'reacticoon/view/hook/useStorageState'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import ListChooser from 'components/ListChooser'
import PublicVariablesContext from 'modules/publicVariables/context'
import SettingsContext from 'modules/settings/context'

const useCadFactionChooser = () => {
  const [loggedInFaction, setSelectedCadFaction] = useStorageState('CAD_FACTION_SELECTED', null)
  const publicVariables = useContext(PublicVariablesContext);
  const settings = useContext(SettingsContext);

  useDebugValue("useCadFactionChooser: " + loggedInFaction)

  const jobs = publicVariables?.jobs

  const options = useMemo(() => {
    if (!jobs || !settings) {
      console.log({ debug: false, jobs, settings })
      return []
    }

    return settings.find(s => s.name === 'cadFactions').value
      .map(faction => {
        if (SecurityManager.hasPermission("api.cad") || SecurityManager.hasCadPermission(faction)) {
          return {
            label: jobs.find(f => f.name === faction).label,
            value: faction,
          }
        }

        return null
      })
      .filter(Boolean)
  }, [jobs, settings])

  return {
    loggedInFaction,
    options,
    setSelectedCadFaction,
    renderChooseFactionView: () => {
      return (
        <Paper>
          <Box p={1}>
            <ListChooser
              label="Choisir une faction"
              placeholder="Choisir une faction"
              fullWidth
              disabled={isEmpty(options) || (options.length === 1 && loggedInFaction === options[0].value)}
              value={loggedInFaction || ''}
              options={options}
              onChange={(e) => {
                setSelectedCadFaction(e.target.value)
                window.location.reload()
              }}
            />
          </Box>
        </Paper>
      )
    }
  }
}

export default useCadFactionChooser