import { createFormatter, createListFormatter, createSubObjectFormatter } from 'reacticoon/format'
import { formatPrice } from 'modules/currency/format'
import { formatJobs } from 'modules/jobGrades/format'

const formatFineType = fineType => {
  fineType.amountFormatted = formatPrice(fineType.amount)
  return fineType
}

export const formatPublicVariablesList = createFormatter(
  createSubObjectFormatter('fineTypes', createListFormatter(formatFineType)),
  createSubObjectFormatter('jobs', formatJobs)
)