import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from "@material-ui/core/styles"
import UserMeContext from "components/UserMeContext"
import WinterLoading from 'components/WinterLoading'
// import Snowfall from 'react-snowfall'
import SecurityManager from 'modules/security/SecurityManager'
import isEmpty from 'lodash/isEmpty'
import { getQueryParam, Query } from 'reacticoon/routing'
import { compose } from 'reacticoon/view'
import classNames from 'classnames'
import useAddFlashMessage from 'reacticoon-plugin-flash-messages/useAddFlashMessage'
import useMountEffect from 'reacticoon/view/hook/useMountEffect'
import { Link, getRoute } from 'reacticoon/routing'
import { findIndexOnArray } from 'reacticoon/utils/array'
import useStorageState from 'reacticoon/view/hook/useStorageState'
import useIsMobile from 'components/useIsMobile'
import { withStyles, withTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import MessageBlock from 'components/MessageBlock'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Helmet } from 'reacticoon-plugin-helmet/'
import RouteContext from 'reacticoon/archi/components/RouteContext'
import AuthContext from 'modules/auth/context'
import UserMeContainer from 'modules/userMe/container'
import LoadingBlock from 'app/components/LoadingBlock'
import LoadingPage from 'components/loading/LoadingPage'
import RoutingContainer from 'reacticoon/routing/RoutingContainer'
import ButtonLink from 'components/ButtonLink'
import useCommand from 'components/useCommand'
import ButtonLoading from 'components/ButtonLoading'
import Header from './Header'
import Sidebar from './Sidebar'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
  toolbar: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    minHeight: theme.app.header.height,
  },
  appBar: {
    height: theme.app.header.height,
    // [theme.breakpoints.up('md')]: {
    //   width: `calc(100% - ${theme.app.sidebar.width}px)`,
    // },
    background: theme.app.background.dark,

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: theme.app.sidebar.width,
    width: `calc(100% - ${theme.app.sidebar.width}px)`,
    [theme.breakpoints.down('sm')]: {
      width: `100%`
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.app.sidebar.width,
    [theme.breakpoints.down('sm')]: {
      width: `100vw`
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.app.background.dark,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  appBarSpacerWithTabs: {
    // ...theme.mixins.toolbar,
    height: 40,
    minHeight: 40,
  },
  appBarSpacerContentFull: {
    minHeight: theme.app.header.height,
    height: theme.app.header.height,
  },
  content: {
    ...theme.style.scrollbar,

    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  removeBottomPadding: {
    paddingBottom: 0,
  },
  contentFull: {
    padding: 0,
    overflow: 'hidden',
  },
  loading: {},
  
  announcementWarningMessage: {
    backgroundColor: theme.status.warning,
    padding: theme.spacing(0.5),
    color: 'rgba(107, 114, 128, 1)',

    '& .MuiButton-root': {
      marginLeft: theme.spacing(1),
    },
  },
  announcementInfoMessage: {
    backgroundColor: theme.status.info,
    padding: theme.spacing(2),
  },
  contentWithAnnouncement: {
    marginTop: theme.spacing(8),
  },
  contentWithIsViewingSomeoneElse: {
    marginTop: theme.spacing(3),
  }
})

const UserMe = ({ isLoggedIn, children }) =>
  isLoggedIn ? <UserMeContainer>{children}</UserMeContainer> : children({ me: null })


  const useTabsViewStyles = makeStyles(theme => ({

    tabsPaper: {
      marginTop: 1,
      width: '100%',
      padding: theme.spacing(0),
      background: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'space-between',

      '& .MuiInputBase-root': {
        marginTop: 0,
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.4),
      },
  
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        fontSize: 12
      },
      [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(2),
        // marginRight: theme.spacing(2),
      },
  
      '& .MuiTabs-root': {
        flex: 1,
        ...theme.style.scrollbar,
        height: 32,
        maxHeight: 32,
        minHeight: 32,
        [theme.breakpoints.down('sm')]: {
          minHeight: 30,
          height: 30,
        },
      },
  
      '& .MuiTab-root': {
        height: 32,
        maxHeight: 32,
        minHeight: 32,
        [theme.breakpoints.down('sm')]: {
          minHeight: 30,
          height: 30,
          padding: `3px 6px`
        },
      },
    },
  
    tabsRoot: {
      [theme.breakpoints.down('sm')]: {
        minHeight: 40,
        height: 40,
      },
  
    },
    tabsFlexContainer: {
      // overflowX: 'auto',
    },
  }))

  function TabsView({
    tabsConfig,
    route,
  }) {
  const classes = useTabsViewStyles()

  if (!tabsConfig) {
    return null
  }

  return (
    <React.Fragment>
      <Paper square className={classes.tabsPaper}>

        {tabsConfig.leftView && tabsConfig.leftView()}

        <Tabs
          classes={{ root: classes.tabsRoot, flexContainer: classes.tabsFlexContainer }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          value={
            tabsConfig.selectedTab ??
            findIndexOnArray(
              tabsConfig.tabs,
              (tab) => getRoute(tab.route)?.name === route?.name
            )
          }
        >
          {tabsConfig.tabs.map(
            ({ label, route, routeParams, routeQuery, ...props }) => (
              <Tab
                key={`tab-${label}`}
                component={Link}
                label={label}
                {...props}
                className={classes.tab}
                to={route}
                params={routeParams}
                query={routeQuery}
              />
            )
          )}
        </Tabs>

      </Paper>
      <Box mb={{ xs: 0.5, md: 2 }} />
    </React.Fragment>
  )
}

function Children({
  route,
  routeParams,
  isPending,
  loadingMessage,
  children,
  tabsConfig,
}) {

  if (route?.authRequired) {
    return (
      <>
        {isPending
          ? <LoadingPage show message={loadingMessage} />
          : <>
            <TabsView
              route={route}
              tabsConfig={tabsConfig}
            />
            {children}
          </>
        }
      </>
    )
  }


  return (
    <>
      <Tabs
        tabsConfig={tabsConfig}
        route={route}
      />
      {isPending ? <LoadingPage show message={loadingMessage} /> : children}
    </>
  )

}

const Page = ({
  isFullPage,
  isPending = false,
  // remove padding on content
  isContentFull = false,
  loadingMessage,
  title,
  session,
  route,
  redirectTo,
  updatePageQueries,
  classes,
  children,
  routeParams,
  // remove the bottom padding on the content, use it to make a dashboard with its own scroll
  removeBottomPadding = false,
  getTabsConfig = null,
  tabsConfigParams,
}) => {
  const [isGameMode] = useStorageState('isGameMode', false)
  const [sidebarIsOpen, setSidebarIsOpen] = useStorageState('sidebarIsOpen', true)
  const isMobile = useIsMobile()
  const { addSuccessFlashMessage, addErrorFlashMessage } = useAddFlashMessage()
  // const [snowEnabled, _] = useStorageState('snowEnabled', true)

  React.useEffect(() => {
    if (isGameMode) {
      // remove logs from the game console (f8)
      console.log = () => { }
      console.warn = () => { }
      console.debug = () => { }
      console.info = () => { }

      const handler = function (e) {
        if (e.ctrlKey) {
          e.preventDefault()
          return;
        }
      }

      const handlerMiddleClick = function (e) {
        e.preventDefault()
        return;
      }

      document.addEventListener('click', handler)
      document.addEventListener('auxclick', handlerMiddleClick)
      return () => {
        document.removeEventListener('click', handler)
        document.removeEventListener('auxclick', handlerMiddleClick)
      }
    }
  }, [isGameMode])

  useMountEffect(() => {
    const errorQueryParam = getQueryParam('error')
    if (!isEmpty(errorQueryParam)) {
      addErrorFlashMessage({
        text: errorQueryParam,
      })
      updatePageQueries({ error: Query.REMOVE_ME })
    }
  })

  const handleDrawerOpen = () => {
    setSidebarIsOpen(true)
  }

  const handleDrawerClose = () => {
    setSidebarIsOpen(false)
  }

  if (route?.authRequired === true && !session?.isLoggedIn) {
    redirectTo('LOGIN')
  }

  if (route?.mustBeLogout === true && session?.isLoggedIn) {
    redirectTo('DASHBOARD')
  }

  const {
    runCommand: cancelViewAs,
    isPending: isCancelViewAsPending,
  } = useCommand({
    command: 'AUTH_VIEW_AS_STOP',
    manualRun: true,
    keepDataOnRequest: false,
    onSuccess: (response) => {
      addSuccessFlashMessage({
        text: `Vous avez arrêté de visualiser TASEv3 en tant qu'un autre joueur`,
        data: response.message
      })
      window.location.reload()
      //window.location.replace(window.location.protocol + "//" + window.location.host + "/")
    },
    onFailure: (error) => {
      addErrorFlashMessage({
        text: error?.message || `Erreur lors de l'arrêt de la visualisation en tant qu'un autre joueur`,
        data: error,
      })
    },
  })


  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* {snowEnabled && <Snowfall />} */}

      {/* TODO: titleTemplate does not work. cf RconList */}
      <Helmet titleTemplate="%s - TASEv3" defaultTitle={`${title || '-'} - TASEv3`}>
        <title>{`${title || '-'} - TASEv3`}</title>
      </Helmet>

      {/* TODO: loading in middle of the page */}
      <UserMe isLoggedIn={session?.isLoggedIn}>
        {({ data: me, error: userMeError }) => (
          <PageMe
            route={route}
            handleDrawerClose={handleDrawerClose}
            me={me}
            isMobile={isMobile}
            sidebarIsOpen={sidebarIsOpen}
            classes={classes}
            isContentFull={isContentFull}
            removeBottomPadding={removeBottomPadding}
            tabsConfigParams={tabsConfigParams}
            getTabsConfig={getTabsConfig}
            isGameMode={isGameMode}
            isFullPage={isFullPage}
            routeParams={routeParams}
            loadingMessage={loadingMessage}
            children={children}
            isCancelViewAsPending={isCancelViewAsPending}
            cancelViewAs={cancelViewAs}
            handleDrawerOpen={handleDrawerOpen}
            isPending={isPending}
            title={title}
            userMeError={userMeError}
          />
        )}
      </UserMe>
    </div>
  )
}

function PageMe({
  route,
  handleDrawerClose,
  me,
  isMobile,
  sidebarIsOpen,
  classes,
  isContentFull,
  removeBottomPadding,
  tabsConfigParams,
  getTabsConfig,

  isPending,
  title,
  userMeError,

  isGameMode,
  isFullPage,
  routeParams,
  loadingMessage,
  children,
  isCancelViewAsPending,
  cancelViewAs,
  handleDrawerOpen,
}) {
  const { setUserMe } = useContext(UserMeContext) 

  useEffect(() => {
    setUserMe(me)
  }, [me])

  if (me && !SecurityManager.hasPermission(route?.permissions || [])) {
    //return <p>Permissions required: {(route?.permissions || []).join(', ')}</p>
    return (
      <Box display="flex" alignItems="center" justifyContent="center" style={{ width: '100%' }}>
        <MessageBlock.Info text={`Permissions required: ${(route?.permissions || []).join(', ')}`} />
      </Box>
    )
  }

  if (userMeError) {
    //return <p>TASEv3 n'est pas accessible pour le moment. Veuillez réessayer plus tard.</p>
    return (
      <Box display="flex" alignItems="center" justifyContent="center" style={{ width: '100%' }}>
        <MessageBlock.Info text="TASEv3 n'est pas accessible pour le moment. Veuillez réessayer plus tard." />
      </Box>
    )
  }

  let tabsConfig = null
  if (getTabsConfig) {
    tabsConfig = getTabsConfig({ routeParams, tabsConfigParams, me, })
    tabsConfig.tabs = (tabsConfig.tabs || []).filter((tab) => {
      if (!tab) {
        return null
      }
      const route = getRoute(tab.route)
      return SecurityManager.hasPermission(route.permissions)
    }).filter(Boolean)
  }

  return (
    /* loading me not found and page requires login */
    <LoadingBlock show={route?.authRequired && !me} className={classes.loading}>

      {!isFullPage && !isGameMode && (
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, sidebarIsOpen && classes.appBarShift)}
        >
          <Toolbar disableGutters={!sidebarIsOpen} className={classes.toolbar}>
            <Header
              title={title}
              isLoggedIn={!!me}
              isMobile={isMobile}
              me={me}
              handleDrawerOpen={handleDrawerOpen}
              sidebarIsOpen={sidebarIsOpen}
              route={route}
            />
          </Toolbar>

          {me?.announcement && (
            <div className={classes.announcementInfoMessage}>
              {me?.announcement}
            </div>
          )}

          {me?.isViewingSomeoneElse && (
            <div
              className={classes.announcementWarningMessage}
            >
              <Box display='flex' justifyContent='space-between'>
                <div>
                  Vous visualisez actuellement TASEv3 avec les permissions de {me?.gamePlayer?.fullName}
                </div>
                <div>
                  <ButtonLink 
                    to="PLAYER" 
                    params={{ identifier: me?.isViewingIdentifier }}
                    variant="outlined"
                    size="22"
                    color="primary"
                  >
                    Voir
                  </ButtonLink>
                  <ButtonLoading
                    size="22"
                    variant="outlined"
                    color="primary"
                    onClick={() => cancelViewAs()}
                    isPending={isCancelViewAsPending}
                  >
                    <DeleteForeverIcon />
                  </ButtonLoading>
                </div>
              </Box>
            </div>
          )}
        </AppBar>
      )}

      {!isFullPage && !isGameMode && (
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !sidebarIsOpen && classes.drawerPaperClose
            ),
          }}
          open={sidebarIsOpen}
        >
          <Sidebar
            route={route}
            routeParams={routeParams}
            tabsConfigParams={tabsConfigParams}
            onClose={handleDrawerClose}
            isMobile={isMobile}
            sidebarIsOpen={sidebarIsOpen}
          />
        </Drawer>
      )}

      <main
        className={classNames(classes.content, {
          [classes.contentFull]: isContentFull,
          [classes.removeBottomPadding]: removeBottomPadding
        })}
        dataroute={route?.name}
      >
        {!isFullPage && (
          <div
            className={classNames({
              [classes.appBarSpacer]: !isGameMode,
              [classes.appBarSpacerContentFull]: isContentFull,
              [classes.appBarSpacerWithTabs]: !!tabsConfig && !isGameMode,
              [classes.contentWithAnnouncement]: me?.announcement,
              [classes.contentWithIsViewingSomeoneElse]: me?.isViewingSomeoneElse
            })}
          />
        )}

        {route?.authRequired && !me && <WinterLoading />}

        <Children
          route={route}
          routeParams={routeParams}
          tabsConfigParams={tabsConfigParams}
          me={me}
          isPending={isPending}
          loadingMessage={loadingMessage}
          children={children}
          tabsConfig={tabsConfig}
        />
      </main>
    </LoadingBlock>
  )
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
}

const PageContext = ({ children, ...props }) => (
  <AuthContext.Consumer>
    {(session) => (
      <RouteContext.Consumer>
        {({ route }) => (
          <RoutingContainer>
            {({ redirectTo, updatePageQueries }) => (
              <Page
                {...props}
                session={session}
                route={route}
                redirectTo={redirectTo}
                updatePageQueries={updatePageQueries}
              >
                {children}
              </Page>
            )}
          </RoutingContainer>
        )}
      </RouteContext.Consumer>
    )}
  </AuthContext.Consumer>
)

export default compose(withStyles(styles), withTheme)(PageContext)
