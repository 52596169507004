import { createModule } from 'reacticoon/module'

import * as actions from './actions'
import * as middlewares from './middlewares'
import { registerSelectors } from './selectors'
import reducer from './reducer'

const RegisterModule = createModule('App::RegisterModule', {
  actions,
  reducer,
  selectors: registerSelectors,
  middlewares,
})

export default RegisterModule
