import moment from 'moment'
import './moment-extensions'
import isEmpty from 'lodash/isEmpty'

export function quarterMinutes(date, interval) {
  const remainder = interval - (date.minute() % interval);
  const dateTime = moment(date).subtract(remainder, "minutes")
  return dateTime
}

export function millisecondsToStr(milliseconds, fullString=false) {
  // TIP: to find current time in milliseconds, use:
  // const  current_time_milliseconds = new Date().getTime();
  // return moment.preciseDiff(0, milliseconds)

  function numberEnding(number) {
    return number > 1 ? 's' : ''
  }

  const msg = []
  let temp = Math.floor(milliseconds / 1000)
  const years = Math.floor(temp / 31536000)
  if (years) {
    msg.push(years + `${fullString ? ' an' + numberEnding(years) : 'a'}`)
  }
  //TODO: Months! Maybe weeks?
  const days = Math.floor((temp %= 31536000) / 86400)
  if (days) {
    msg.push(days + `${fullString ? ' jour' + numberEnding(days) : 'j'}`)
  }
  const hours = Math.floor((temp %= 86400) / 3600)
  if (hours) {
    msg.push(hours + `${fullString ? ' heure' + numberEnding(hours) : 'h'}`)
  }
  const minutes = Math.floor((temp %= 3600) / 60)
  if (minutes) {
    msg.push(minutes + `${fullString ? ' minute' + numberEnding(minutes) : 'm'}`)
  }

  const seconds = temp % 60
  if (seconds) {
    msg.push(seconds + `${fullString ? ' seconde' + numberEnding(seconds) : 's'}`)
  }

  if (isEmpty(msg)) {
    return `Moins d'une seconde` //'just now' //or other string you like;
  }
  return msg.join(' ')
}

export function isValidDate(date) {
  if (typeof date === "string") {
    date = new Date(date)
  }
	if ( Object.prototype.toString.call(date) === "[object Date]" ) {
		if ( !isNaN(date.getTime()) ) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}