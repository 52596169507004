import React from 'react'
import PublicVariablesContainer from 'modules/publicVariables/container'
import LoadingBlockWrapper from "components/LoadingBlockWrapper"

import PublicVariablesContext from "./context"

export default function PublicVariablesProvider({ children }) {
	return (
		<PublicVariablesContainer>
			{({ isPending: isPendingPublicVariables, data: publicVariables }) => (
				<PublicVariablesContext.Provider value={publicVariables || {}}>
					{isPendingPublicVariables ? <LoadingBlockWrapper show /> : children}
				</PublicVariablesContext.Provider>
			)}
		</PublicVariablesContainer>
	)
}
