import React, { useContext } from 'react'

import isEmpty from 'lodash/isEmpty'
import { fade, withStyles } from '@material-ui/core/styles'
import { Link } from 'reacticoon/routing'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchContainer from 'modules/search/container'
import PublicVariablesContext from 'modules/publicVariables/context'

const styles = (theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
  popup: {
    position: 'absolute',
    top: 40,
    minWidth: 480,
    width: '100%',
    maxWidth: 860,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      left: theme.spacing(2),
      width: '90vw',
      minWidth: '90vw',
      maxWidth: '90vw',
    },

    '& li:hover': {
      cursor: 'pointer',
      // TODO: from theme
      background: 'rgba(255, 255, 255, 0.14)',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  history: {
    overflow: 'hidden',
    overflowY: 'auto',
    maxHeight: '60vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  actions: {
    marginLeft: theme.spacing(2),
    '& a': {
      marginLeft: theme.spacing(1),

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  historyBtn: {
    cursor: 'pointer',
    width: 32,
  },
  searchPending: {
    marginRight: theme.spacing(0.5),
  },
  fullName: {
    // take whole remaining space
    display: 'flex',
    flex: 1,
  },
  inGamePlayerInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  isOnlineDot: {
    background: theme.status.valid,
    height: 8,
    width: 8,
    borderRadius: '80%',
    marginRight: theme.spacing(0.5),
  },
})

const Search = ({ route, classes }) => {
  const [isFocus, setIsFocus] = React.useState(false)

  const { weapons } = useContext(PublicVariablesContext)

  const onQuitFocus = () => setIsFocus(false)

  return (
    <SearchContainer disableAutomaticSend>
      {({
        query,
        onQueryChange,
        onSubmit,
        onResetQuery,
        results,
        isSearchPending,
        searchHistory,
        showHistory,
        setShowHistory,
      }) => (
        <ClickAwayListener
          onClickAway={() => {
            onResetQuery()
            onQuitFocus()
          }}
        >
          <div
            className={classes.search}
            // onBlur={() => setIsFocus(false)}
          >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Rechercher"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={query}
              onChange={(e) => onQueryChange(e.target.value)}
              onFocus={() => setIsFocus(true)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  onSubmit()
                }
              }}
              endAdornment={
                isSearchPending ? (
                  <CircularProgress size={28} className={classes.searchPending} />
                ) : (
                  <ExpandMoreIcon
                    onClick={() => setShowHistory(!showHistory)}
                    className={classes.historyBtn}
                  />
                )
              }
            />

            {showHistory && (
              <ClickAwayListener onClickAway={() => setShowHistory(false)}>
                <Paper className={classes.popup} variant="outlined" elevation={3}>
                  <List className={classes.history}>
                    {isEmpty(searchHistory) ? (
                      <ListItem>
                        <ListItemText primary="Pas d'historique" />
                      </ListItem>
                    ) : (
                      searchHistory.map((query, index) => (
                        <ListItem
                          key={index}
                          className={classes.listItem}
                          onClick={() => {
                            onQueryChange(query)
                            onSubmit(query)
                          }}
                        >
                          {query}
                        </ListItem>
                      ))
                    )}
                  </List>
                </Paper>
              </ClickAwayListener>
            )}

            {isFocus && results && !showHistory && (
              <Paper className={classes.popup} variant="outlined" elevation={3}>
                <List>
                  {isEmpty(results) ? (
                    <ListItem>
                      <ListItemText primary="Pas de résultat" />
                    </ListItem>
                  ) : (
                    results.map((result, index) => (
                      <ListItem className={classes.listItem} key={index} data={result}>
                        {result.type === 'faction' && (
                          <Link 
                            to="FACTION"
                            query={{
                              faction: result.data.faction.name,
                              tab: 'members',
                            }}
                            className={classes.fullName}
                            onClick={() => {
                              if (route.name === 'FACTION') {
                                // TODO: when already on the FACTION page, it does not change the selected faction
                                setTimeout(() => {
                                  window.location.reload()
                                }, 1000)
                              }
                              onQuitFocus()
                            }}
                          >
                            Faction : {result.data.faction.label}
                          </Link>
                        )}
                        {result.type === 'factionVehicle' && (
                          <Link
                            to="FACTION"
                            query={{
                              faction: result.data.faction,
                              tab: 'vehicles',
                              plate: result.data.plate.toUpperCase(),
                            }}
                            className={classes.fullName}
                          >
                            Véhicule de faction {result.data.label}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'onplayer' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocation }}
                            className={classes.fullName}
                            query={
                              {
                                tab: 'inventory',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} sur le joueur ${result.data.weaponLocationTypeDetail}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'bag' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.identifier }}
                            className={classes.fullName}
                            query={
                              {
                                tab: 'inventory',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans le sac du joueur ${result.data.weaponLocationTypeDetail.data.fullName}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'civ_vehicle' && (
                          <Link
                            to={'PLAYER_VEHICLES'}
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.plate && {
                                plate: result.data.weaponLocationTypeDetail.data.plate.toUpperCase(),
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans le véhicule ${result.data.weaponLocationTypeDetail.data.plate} du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'faction_vehicle' && (
                          <Link
                            to="FACTION"
                            query={{
                              faction: result.data.weaponLocationTypeDetail.data.owner,
                              tab: 'vehicles',
                              plate: result.data.weaponLocationTypeDetail.data.plate.toUpperCase(),
                              weaponSn: result.data.weaponData.data.sn
                            }}
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans le véhicule ${result.data.weaponLocationTypeDetail.data.plate} de la faction ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'prop' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.propId && {
                                propId: result.data.weaponLocationTypeDetail.data.propId,
                                tab: 'inventory',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans le prop ${result.data.weaponLocationTypeDetail.data.propId} du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'donatorFarm' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.type && {
                                inventoryId: `donatorFarm_${result.data.weaponLocationTypeDetail.data.type}`,
                                tab: 'inventory',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans le coffre ${result.data.weaponLocationTypeDetail.data.typeLabel} du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'locker' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.type && {
                                inventoryId: `locker_${result.data.weaponLocationTypeDetail.data.type}`,
                                tab: 'inventory',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans le casier ${result.data.weaponLocationTypeDetail.data.typeLabel} du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'property' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.propertyId && {
                                propertyId: result.data.weaponLocationTypeDetail.data.propertyId,
                                tab: 'properties',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans une propriété du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'oldproperty' && (
                          `Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans une ancienne propriété du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel} (non visible)`
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'property_letterbox' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.propertyId && {
                                propertyId: result.data.weaponLocationTypeDetail.data.propertyId,
                                tab: 'properties',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans la boite aux lettres d'une propriété du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'property_letterbox_notowned' && (
                          `Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans la boite aux lettres d'une ancienne propriété (${result.data.weaponLocationTypeDetail.data.propertyData}) (non visible)`
                        )}
                        
                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'motelinv' && (
                          <Link
                            to="PLAYER"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.type && {
                                inventoryId: result.data.weaponLocationTypeDetail.data.type,
                                tab: 'inventory',
                                weaponSn: result.data.weaponData.data.sn
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans la chambre d'hôtel ${result.data.weaponLocationTypeDetail.data.typeLabel} du joueur ${result.data.weaponLocationTypeDetail.data.ownerLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'inventory_society' && (
                          <Link
                            to="FACTION"
                            params={{ identifier: result.data.weaponLocationTypeDetail.data.owner }}
                            className={classes.fullName}
                            query={
                              result.data.weaponLocationTypeDetail.data.faction && {
                                faction: result.data.weaponLocationTypeDetail.data.faction,
                                tab: 'weapons',
                                safe: result.data.weaponLocationTypeDetail.data.safe,
                                weaponSn: result.data.weaponData.data.sn,
                                weaponClassName: result.data.weaponData.name
                              }
                            }
                          >
                            {`Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans l'inventaire ${result.data.weaponLocationTypeDetail.data.safe} de la faction ${result.data.weaponLocationTypeDetail.data.factionLabel}`}
                          </Link>
                        )}

                        {result.type === 'weapon' && result.data.weaponLocationType === 'bayinventory' && result.data.weaponLocationTypeDetail.type === 'unknowninventory' && (
                          `Arme : ${weapons.find(e => e.name === result.data.weaponData.name).label} dans un inventaire inconnu (${result.data.weaponLocation}) (non visible)`
                        )}
                      
                        {result.type === 'playerVehicle' && (
                          <Link
                            to={result.data.plate ? 'PLAYER_VEHICLES' : 'PLAYER'}
                            params={{ identifier: result.data.player.identifier }}
                            query={
                              result.data.plate && {
                                plate: result.data.plate.toUpperCase(),
                              }
                            }
                            className={classes.fullName}
                          >
                            Véhicule du joueur {result.data.player.fullName}
                          </Link>
                        )}

                        {result.type === 'oldProperty' && (
                          <Link
                            to='PLAYER'
                            params={{ identifier: result.data.player.identifier }}
                            className={classes.fullName}
                          >
                            Ancienne propriété de {result.data.player.fullName}
                          </Link>
                        )}

                        {result.type === 'property' && (
                          <Link
                            to='PLAYER'
                            params={{ identifier: result.data.player.identifier }}
                            query={{
                              propertyId: result.data.propertyId,
                              tab: 'properties'
                            }}
                            className={classes.fullName}
                          >
                            Propriété de {result.data.player.fullName}
                          </Link>
                        )}

                        {result.type === 'playerProp' && (
                          <Link
                            to='PLAYER'
                            params={{ identifier: result.data.player.identifier }}
                            query={
                              result.data.propId && {
                                propId: result.data.propId,
                                tab: 'inventory'
                              }
                            }
                            className={classes.fullName}
                            onClick={onQuitFocus}
                          >
                            Prop du joueur {result.data.player.fullName}
                          </Link>
                        )}

                        {(result.type === 'player' || result.type === 'inGamePlayer') && (
                          <React.Fragment>
                            <Link
                              to={result.data.plate ? 'PLAYER_VEHICLES' : 'PLAYER'}
                              params={{ identifier: result.data.player.identifier }}
                              query={
                                result.data.plate && {
                                  plate: result.data.plate,
                                }
                              }
                              className={classes.fullName}
                              onClick={onQuitFocus}
                            >
                              {result.type === 'inGamePlayer' ? (
                                <span className={classes.inGamePlayerInfo}>
                                  <span className={classes.isOnlineDot} />
                                  <span>
                                    [{result.data.player.inGameId}] {result.data.player.fullName}
                                  </span>{' '}
                                </span>
                              ) : (
                                <span>{result.data.player.fullName}</span>
                              )}
                            </Link>

                            <div className={classes.actions}>
                              <Link
                                to="PLAYER"
                                params={{ identifier: result.data.player.identifier }}
                                onClick={onQuitFocus}
                              >
                                Gestion
                              </Link>

                              <Link
                                to="PLAYER_RECORD"
                                params={{ identifier: result.data.player.identifier }}
                                onClick={onQuitFocus}
                              >
                                Casier
                              </Link>

                              <Link
                                to="PLAYER_TICKETS"
                                params={{ identifier: result.data.player.identifier, displayId: 'all' }}
                                onClick={onQuitFocus}
                              >
                                Tickets
                              </Link>

                              <Link
                                to="PLAYER_SPY"
                                params={{ identifier: result.data.player.identifier }}
                                onClick={onQuitFocus}
                              >
                                Spy
                              </Link>

                              <Link
                                to="LOGS"
                                query={{ identifier: result.data.player.identifier }}
                                onClick={onQuitFocus}
                              >
                                Logs
                              </Link>
                            </div>
                          </React.Fragment>
                        )}
                      </ListItem>
                    ))
                  )}
                </List>
              </Paper>
            )}
          </div>
        </ClickAwayListener>
      )}
    </SearchContainer>
  )
}

export default withStyles(styles)(Search)
