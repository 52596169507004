import React from 'react'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import forEach from 'lodash/forEach'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

import { withStyles, withTheme } from '@material-ui/core/styles'

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const styles = (theme) => {
  let stylesObject = {
  }
  stylesObject['alignCenter'] = {
    textAlign: 'center',
  }
  stylesObject['alignLeft'] = {
    textAlign: 'left',
  }
  stylesObject['alignRight'] = {
    textAlign: 'right',
  }
  stylesObject['defaultThemeTextColor'] = {
    color: theme.palette.text.primary,
  }

  forEach(theme.typography.fonts, (value, key) => {
    stylesObject[key] = value.style
  })

  return stylesObject
}

// This component is used to set our own font theme's styles for texts
// property component - String - specify the HTML entity you want to wrap your children
// property type - String - specify the type of font to get from theme
// property align - String - align text (values : right - left - center)

class TypographyCustom extends React.PureComponent {
  render() {
    const {
      component,
      type,
      align,
      skipColor = false,
      classes,
      className,
      onClick,
      theme,
      html,
      children,
      ...otherProps
    } = this.props

    const alignDefault = align ? align : 'left'

    invariant(
      !isUndefined(classes[type]),
      `The props type "${type}" specified for TypographyCustom does not exist in your theme`
    )

    const classNameCombined = clsx(
      classes[type],
      classes[`align${capitalizeFirstLetter(alignDefault)}`],
      className,
      {
        [classes.defaultThemeTextColor]: !skipColor,
      }
    )

    const Component = component
      ? component
      : theme.typography.fonts[type].component
      ? theme.typography.fonts[type].component
      : 'div'

    const dangerouslySetInnerHTML = !isNil(html) ? { __html: html } : null

    // we keep this solution in case we want to transmit
    // the applied styled from TypographyCustom to an other component
    // this is useless for text ;)
    // we don't add the wrapper component because it duplicates code with same class
    if (isFunction(children)) {
      return children({
        className: classNameCombined,
        onClick,
        dangerouslySetInnerHTML,
        ...otherProps,
      })
    }

    return (
      <Component
        className={classNameCombined}
        onClick={onClick}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        {...otherProps}
      >
        {children}
      </Component>
    )
  }
}

TypographyCustom.defaultProps = {
  skipColor: false,
}

TypographyCustom.propTypes = {
  component: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  align: PropTypes.string,
  // if skipColor, the default theme text primary color is not applied
  skipColor: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
    PropTypes.element,
  ]),
}

export default withTheme(withStyles(styles)(TypographyCustom))
