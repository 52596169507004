import React, { useState } from 'react'
import { findOnArray } from 'reacticoon/utils/array'
import useCommand from 'components/useCommand'
import useStorageState from 'reacticoon/view/hook/useStorageState'
import useMountEffect from 'reacticoon/view/hook/useMountEffect'
import useAddFlashMessage from 'reacticoon-plugin-flash-messages/useAddFlashMessage'
import SettingsContext from '../modules/settings/context'

const MajView = ({ children }) => {
  const [settings, setSettings] = useState(null)
  const [version, setVersion] = useStorageState('APP_VERSION', null)
  const [hasBeenUpdated, setHasBeenUpdate] = useStorageState('APP_VERSION_UPDATED', true)
  const { addSuccessFlashMessage } = useAddFlashMessage()

  useMountEffect(() => {
    if (hasBeenUpdated) {
      addSuccessFlashMessage({
        text: `TASEv3 a été mise à jour`,
        duration: 5000
      })
      setHasBeenUpdate(false)
    }
  })

  useCommand({
    command: 'GET_SETTINGS',
    payload: {},
    displayError: false,
    manualPending: true,
    keepDataOnRequest: true,
    onSuccess: (response) => {
      setSettings(response) // TRICK: FUCK THIS, infinite loop if we use const { data: settings } = useCommand
      
      const responseVersion = findOnArray(response, setting => setting.name === 'front_version')
      // TODO: do not reload if version is nil, to avoid reloading first time we open tase.
      if (responseVersion && version !== responseVersion.value) {
        setVersion(responseVersion.value)
        setTimeout(() => {
          window.location.reload(true)
          setHasBeenUpdate(true)
        }, 400)
      }
    }
  })

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  )
}

export default MajView