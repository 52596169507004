const locks = {}

function buildKey(notificationType, id) {
	return `${notificationType}/${id}`
}

function lock(notificationType, id) {
	const key = buildKey(notificationType, id)
	console.log(`[notification] lock ${key}`)
	locks[key] = true
}

function unlock(notificationType, id) {
	const key = buildKey(notificationType, id)
	console.log(`[notification] unlock ${key}`)
	locks[key] = false
}

function isLocked(notificationType, id) {
	const key = buildKey(notificationType, id)
	return locks[key] === true
}


// possibles locks:
// 'TICKET_MESSAGE_RECEIVED', ticketId

export default {
	lock,
	unlock,
	isLocked,
}