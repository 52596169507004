// import { formatPlayer } from 'modules/player/format'
import { millisecondsToStr } from 'modules/date'

export const formatPlayerAndRconPlayer = (player) => {
  // TODO: remove trick
  // player = { ...player }
  // player.timePlayed = player.timePlayed
  player.currentTimePlayedFormatted = millisecondsToStr(player.currentTimePlayed * 60000)
  player.timePlayedFormatted = millisecondsToStr(player.timePlayed * 60000)

  // timePlayer in minutes
  if (!player.timePlayed) {
    player.seniorityLabel = 'Inconnu'
    player.isSeniorityUnknown = true
  } else if (player.timePlayed < 60 * 4) { // 4 hours
    player.seniorityLabel = 'Nouveau'
    player.isSeniorityNew = true
  } else if (player.timePlayed < 60 * 12) { // 12 hours
    player.seniorityLabel = 'Débutant'
    player.isSeniorityBeginner = true
  } else if (player.timePlayed < 1440 * 3) { // 3 jours
    player.seniorityLabel = 'Habitué'
    player.isSeniorityRegular = true
  } else if (player.timePlayed < 1440 * 15) { // 1 week * 2
    player.seniorityLabel = 'Vétéran'
    player.isSeniorityVeteran = true
  } else if (player.timePlayed < 1440 * 30) { // 1 month
    player.seniorityLabel = 'Expert'
    player.isSeniorityExpert = true
  } else if (player.timePlayed < 1440 * 2 * 30) { // 2 months
    player.seniorityLabel = 'Roleplayer'
    player.isSeniorityRolePlayer = true
  } else {
    player.seniorityLabel = 'No life'
    player.isSeniorityNoLife = true
  }


  return player
}