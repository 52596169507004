import useMediaQuery from '@material-ui/core/useMediaQuery'

function useIsMobile() {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'), 
	// force noSsr, otherwise isMobile is false then true
	{ noSsr: true });

	return isMobile
}

export default useIsMobile