import React from 'react'

import { createLoadable } from 'reacticoon/view'
import PageLoader from '../components/PageLoader'

const createAsyncGamePage = loader => createLoadable(loader, () => <div />)

const routing = (api) => {
  api.registerAsyncLoaderView(<PageLoader />)

  return [
    // {
    //   name: 'TEST',
    //   path: '/test',
    //   authRequired: true,
    //   handler: createAsyncGamePage(() =>
    //     import(/*  webpackChunkName: "TestPage" */ '../pages/test')
    //   ),
    // },


    {
      name: 'DASHBOARD',
      path: '/',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "DashboardPage" */ '../pages/dashboard')
      ),
    },
    {
      name: 'LOGIN',
      path: '/login',
      authRequired: false,
      mustBeLogout: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "LoginPage" */ '../pages/login')
      ),
    },
    {
      name: 'REGISTER',
      path: '/register',
      authRequired: false,
      mustBeLogout: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RegisterPage" */ '../pages/register')
      ),
    },
    {
      name: 'REGISTER_STEP_2',
      path: '/register/integrations',
      authRequired: false,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RegisterIntegrationsPage" */ '../pages/registerIntegrations')
      ),
    },
    {
      name: 'LOGOUT',
      path: '/logout',
      authRequired: false,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "LogoutPage" */ '../pages/logout')
      ),
    },
    {
      name: 'PASSWORD_FORGOTTEN',
      path: '/password-forgotten',
      authRequired: false,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PasswordForgotten" */ '../pages/passwordForgotten')
      ),
    },
    {
      name: 'PASSWORD_RECOVERY',
      path: '/password-recovery',
      authRequired: false,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PasswordRecovery" */ '../pages/passwordRecovery')
      ),
    },
    {
      name: 'USER_SETTINGS',
      path: '/user-settings',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "UserSettingsPage" */ '../pages/userSettings')
      ),
    },
    {
      name: 'STATS',
      path: '/stats',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "ServerPage" */ '../pages/stats')
      ),
      permissions: ['api.gestion.server'],
    },
    {
      name: 'PLAYER',
      path: '/players/:identifier',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PlayerPage" */ '../pages/player')
      ),
      permissions: ['api.player'],
    },
    {
      name: 'PLAYER_RECORD',
      path: '/players/:identifier/record',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PlayerRecordPage" */ '../pages/playerRecord')
      ),
      permissions: ['api.criminalRecord'],
    },
    {
      name: 'PLAYER_TICKETS',
      path: '/players/:identifier/tickets/:displayId',
      defaultParams: {
        displayId: "all",
      },
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PlayerTicketPage" */ '../pages/playerTickets')
      ),
      permissions: ['api.ticket'],
    },
    
    {
      name: 'PLAYER_SPY',
      path: '/players/:identifier/spy',
      authRequired: true,
      handler: api.createAsyncPage(() => import(/*  webpackChunkName: "SpyPage" */ '../pages/spy')),
      permissions: ['api.spy'],
    },
    {
      name: 'PLAYER_POSITION_HISTORY',
      path: '/players/:identifier/position/history',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PlayerPositionHistoryPage" */ '../pages/playerPositionHistory')
      ),
      permissions: ['api.player.canGetAllPlayersPositionHistory'],
    },
    {
      name: 'PLAYER_VEHICLES',
      path: '/players/:identifier/vehicles',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PlayerVehiclesPage" */ '../pages/playerVehicles')
      ),
      permissions: ['api.player'],
    },
    {
      name: 'PLAYER_PETS',
      path: '/players/:identifier/pets',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PlayerPetsPage" */ '../pages/playerPets')
      ),
      permissions: ['api.player'],
    },
    {
      name: 'SERVER_DASHBOARD',
      path: '/server-dashboard',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "ServerDashboardPage" */ '../pages/serverDashboard')
      ),
      permissions: ['api.gestion.dashboard'],
    },
    {
      name: 'FACTION_COMPATIBILITY',
      path: '/faction-compatibility',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "FactionCompatibilityPage" */ '../pages/factionCompatibility')
      ),
      permissions: ['api.gestion.factions'],
    },
    {
      name: 'VEHICLES',
      path: '/vehicles',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "VehiclesPage" */ '../pages/vehicles')
      ),
      permissions: ['api.administration.vehicles'],
    },
    {
      name: 'GAME_RESOURCES',
      path: '/resources',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "GameResourcesPage" */ '../pages/gameResources')
      ),
      permissions: ['api.gestion.resources'],
    },
    {
      name: 'LIVE_CONSOLE',
      path: '/console',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "LiveConsolePage" */ '../pages/liveConsole')
      ),
      permissions: ['api.liveConsole'],
    },
    {
      name: 'BANLIST',
      path: '/banlist',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "BanlistPage" */ '../pages/banlist')
      ),
      permissions: ['api.banlist'],
    },
    {
      name: 'DARKCHAT',
      path: '/darkChat',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "DarkChatPage" */ '../pages/darkChat')
      ),
      permissions: ['api.darkChat'],
    },
    {
      name: 'SIEM',
      path: '/siem',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "SiemPage" */ '../pages/siem')
      ),
      permissions: ['api.siem'],
    },
    {
      name: 'LOGS',
      path: '/logs',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "LogsPage" */ '../pages/logs')
      ),
      permissions: ['api.logs'],
    },
    {
      name: 'RCON',
      path: '/rcon',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RconPage" */ '../pages/rcon')
      ),
      permissions: ['api.playerList'],
    },
    {
      name: 'REFUNDS',
      path: '/refunds',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RefundsPage" */ '../pages/refunds')
      ),
      permissions: ['api.gestion.refunds'],
    },

    //
    //
    //
    {
      name: 'REPORTS',
      path: '/reports',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "ReportsPage" */ '../pages/reports')
      ),
      permissions: ['api.playerList.canReply'], 
    },

    {
      name: 'TICKET_HISTORY',
      path: '/tickets/history/:displayId',
      defaultParams: {
        displayId: 'all',
      },
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "TicketsHistoryPage" */ '../pages/ticketsHistory')
      ),
      permissions: ['api.ticket'],
    },

    {
      name: 'TICKET_SETTINGS',
      path: '/tickets/settings',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "TicketsSettingsPage" */ '../pages/ticketsSettings')
      ),
      permissions: ['api.ticket'], 
    },

    {
      name: 'TICKET_BLACKLIST',
      path: '/tickets/blacklist',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "TicketsBlacklistPage" */ '../pages/ticketsBlacklist')
      ),
      permissions: ['api.ticket.canBlacklist'],
    },


    {
      name: 'TICKET',
      path: '/tickets/:displayId',
      defaultParams: {
        displayId: "all",
      },
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "TicketPage" */ '../pages/tickets')
      ),
      permissions: ['api.ticket'], 
    },

    {
      name: 'ADMIN_LIST',
      path: '/admins',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "AdminListPage" */ '../pages/adminList')
      ),
      permissions: ['api.administration.adminList'],
    },

    {
      name: 'TASE_LOGS',
      path: '/tase-logs',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "TaseLogsPage" */ '../pages/taseLogs')
      ),
      permissions: ['api.administration.history'],
    },

    {
      name: 'SETTINGS',
      path: '/settings',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "SettingsPage" */ '../pages/settings')
      ),
      permissions: ['api.administration.settings'],
    },

    //
    //
    //

    {
      name: 'FACTION',
      path: '/factions',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "FactionPolicePage" */ '../pages/factionWhitelist')
      ),
      permissions: ['api.gestion.factions'],
    },
    {
      name: 'MAP',
      path: '/map',
      authRequired: true,
      handler: api.createAsyncPage(() => import(/*  webpackChunkName: "MapPage" */ '../pages/map')),
    },
    {
      name: 'PRICING',
      path: '/pricing',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "PricingPage" */ '../pages/pricing')
      ),
    },
    {
      name: 'RANKING_MONEY',
      path: '/ranking/money',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RankingMoneyPage" */ '../pages/rankingMoney')
      ),
      permissions: ['api.ranking'],
    },
    {
      name: 'RANKING_MONEY_JOB',
      path: '/ranking/money-job',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RankingMoneyPage" */ '../pages/rankingMoneyJob')
      ),
      permissions: ['api.ranking'],
    },
    {
      name: 'RANKING_PLAYED_TIME',
      path: '/ranking/played-time',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RankingPlayedTimePage" */ '../pages/rankingPlayedTime')
      ),
      permissions: ['api.ranking'],
    },
    {
      name: 'RANKING_FEES',
      path: '/ranking/fees',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RankingPlayedTimePage" */ '../pages/rankingFees')
      ),
      permissions: ['api.ranking'],
    },
    {
      name: 'RANKING_LEADERBOARD',
      path: '/ranking/leaderboard',
      authRequired: true,
      handler: api.createAsyncPage(() =>
        import(/*  webpackChunkName: "RankingLeaderboardPage" */ '../pages/rankingLeaderboard')
      ),
      permissions: ['api.ranking'],
    },

    //
    // GAME pages
    //

    {
      name: 'GAME_REDIRECT',
      path: '/g/redirect',
      authRequired: false,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "GameRedirectPage" */ '../pages/gameRedirect')
      ),
    },

    {
      name: 'GAME_AUTH',
      path: '/g/auth',
      authRequired: false,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "GameAuthPage" */ '../pages/gameAuth')
      ),
    },

    //
    // ADDON cad
    //

    {
      name: 'CAD_DASHBOARD',
      path: '/cad/dashboard',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "cadDashboardPage" */ '../pages/cad/dashboard')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_WANTED_PLAYERS',
      path: '/cad/wanted',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadWantedPage" */ '../pages/cad/wantedPlayers')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_WANTED_VEHICLES',
      path: '/cad/wanted-vehicles',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadWantedVehiclesPage" */ '../pages/cad/wantedVehicles')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_FACTION_VEHICLES',
      path: '/cad/faction-vehicles',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadWantedVehiclesPage" */ '../pages/cad/factionVehicles')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_FACTION_PETS',
      path: '/cad/faction-pets',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadWantedPetsPage" */ '../pages/cad/factionPets')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_WORKFORCE',
      path: '/cad/workforce',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadWorkforcePage" */ '../pages/cad/workforce')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_SEARCH',
      path: '/cad/search',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadSearchPage" */ '../pages/cad/search')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_HELP',
      path: '/cad/help',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadHelpPage" */ '../pages/cad/help')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_PERMISSIONS',
      path: '/cad/permissions',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadPermissionsPage" */ '../pages/cad/permissions')
      ),
      permissions: ['api.cad', 'cad'],
    },

    {
      name: 'CAD_WEAPONS',
      path: '/cad/weapons',
      authRequired: true,
      handler: createAsyncGamePage(() =>
        import(/*  webpackChunkName: "CadWeaponsPage" */ '../pages/cad/weapons')
      ),
      permissions: ['api.cad', 'cad'],
    },


  ]
}

export default routing
