import React from 'react'

import isEmpty from 'lodash/isEmpty'
import { findIndexOnArray } from 'reacticoon/utils/array'
import { createModuleContainer } from 'reacticoon/container'
import SearchModule from 'modules/search'
import useStorageState from 'reacticoon/view/hook/useStorageState'

const SearchModuleContainer = createModuleContainer(
  'App::SearchModule::SearchModuleContainer',
  SearchModule,
  {
    selectors: {
      isPending: 'isPending',
      results: 'getData',
      error: 'getError',
    },
    actions: ['fetchSearch'],
  }
)

let timeout = null

function updateHistory(query, searchHistory) {
  const index = findIndexOnArray(searchHistory, (q) => q?.startsWith(query))
  if (index === -1) {
    return [query, ...(searchHistory || [])].slice(0, 25) // keep 25 last search
  }
  return searchHistory.filter(Boolean)
  // TODO:put query on top
  //return [ query, ...deleteObjectAtIndexOnArray(searchHistory, index)]
}

const SearchContainer = ({ disableAutomaticSend = false, children }) => {
  const [query, setQuery] = React.useState(null)
  const [searchHistory, setSearchHistory] = useStorageState('search_player_history', [])
  const [showHistory, setShowHistory] = React.useState(false)

  return (
    <SearchModuleContainer>
      {({ fetchSearch, results = [], isPending }) =>
        children({
          query: query || '',
          onQueryChange: (query) => {
            setQuery(query)
            if (!disableAutomaticSend) {
              if (query && query.length >= 2) {
                setShowHistory(false)
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                  fetchSearch({ query })
                  setSearchHistory(updateHistory(query, searchHistory))
                }, 300)
              }
            }
          },
          onResetQuery: () => {
            setQuery(null)
          },
          onSubmit: (queryParam = null) => {
            const finalQuery = queryParam || query
            if (isEmpty(finalQuery)) {
              return
            }
            setShowHistory(false)
            fetchSearch({
              query: finalQuery,
            })
            setSearchHistory(updateHistory(finalQuery, searchHistory))
          },
          //results: !query ? null : results,
          results,
          isSearchPending: isPending,
          searchHistory: searchHistory || [],
          showHistory,
          setShowHistory,
        })
      }
    </SearchModuleContainer>
  )
}

export default SearchContainer
