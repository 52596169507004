//
// Exports
//

export * from './predicates'

//
// The validator validate the forms `formData`. The data is given via the `formErrors` prop.
//
export { default as reacticoonValidator } from './validator'
