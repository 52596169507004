import React from "react";
import PropTypes from "prop-types";

import { Link, getRoute } from "reacticoon/routing";
import TaseButton from "./TaseButton";

/**
 * Component that combine a Button and a Link
 * See https://material-ui-next.com/demos/buttons/#third-party-routing-library
 */
const ButtonLink = React.forwardRef(
  ({ to, params, query, newTab, children, target, href, ...otherProps }, ref) =>
    href ? (
      <TaseButton
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        component="a"
        href={href}
        target={newTab ? '_blank' : target}
        ref={ref}
        {...otherProps}
      >
        {children}
      </TaseButton>
    ) : (
      <TaseButton
        // pass Link like this and not like <Link ... />, to avoid some problems: clicking on the link
        // was redirecting but by loading the page instead of just pushing in history.
        ref={ref}
        component={Link}
        to={to}
        params={params}
        query={query}
        target={newTab ? '_blank' : target}
        {...otherProps}
      >
        {children}
      </TaseButton>
    )
)

ButtonLink.getRoute = getRoute;

ButtonLink.defaultProps = {
  newTab: false,
  variant: "contained",
  color: "primary"
};

ButtonLink.propTypes = {
  to: PropTypes.any,

  params: PropTypes.object,

  query: PropTypes.object,

  /**
   * true if the link must be opened in a new tab
   */
  newTab: PropTypes.bool
};

export default ButtonLink;
