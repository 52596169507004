import { createApiCallAction } from 'reacticoon/action'

export const fetchSearch = createApiCallAction('App::SearchModule::fetchSearch', (payload) => ({
  type: 'POST',
  endpoint: '/commands/GET_QUERY_RESULTS',
  body: {
    command: 'GET_QUERY_RESULTS',
    payload,
  },
  /*response: [
  ],*/
}))
