import { createModule } from 'reacticoon/module'

import * as actions from './actions'
import * as middlewares from './middlewares'
import { fetchUserMeSelectors } from './selectors'
import reducer from './reducer'

const UserMeModule = createModule('App::UserMeModule', {
  reducer,
  actions,
  selectors: fetchUserMeSelectors,
  middlewares,
})

export default UserMeModule
