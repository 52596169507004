import { createMiddleware } from 'reacticoon/middleware'

import { runCommand } from './actions'

export const onCommandSuccessMiddleware = createMiddleware(
  'App::CommandModule::onCommandSuccessMiddleware',
  runCommand.SUCCESS,
  ({ action }) => {
    action.payload.onSuccess && action.payload.onSuccess(action.response)
  }
)

export const onCommandFailureMiddleware = createMiddleware(
  'App::CommandModule::onCommandFailureMiddleware',
  runCommand.FAILURE,
  ({ action }) => {
    action.payload.onFailure && action.payload.onFailure(action.apiError || action.response || action)
  }
)
