import DOMPurify from 'dompurify';

function formatHtmlContent(content) {
	const originalContent = content 

	if (typeof content === 'string' || content instanceof String) {
		content = content.replace(/<</g, "");
		content = content.replace(/>>/g, "");
	}

	// since we will dangerouslySetInnerHTML we first sanitize text received.
	content = DOMPurify.sanitize(content)
	
	if ((content.includes("youtube.com") || content.includes("youtu.be")) && !content.includes("/clip/")) {
		// https://youtu.be/lI8VfkiSHXI?t=2241
		// https://youtu.be/k7i-dd5cVXM


		// http://localhost:4242/players/5a828839106a13f9057037fe7ede661b1b0551fb/record
		const params = new URLSearchParams(content);
		content = content.replace(
			/(?:https:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?.*v=)?([\w-]+)([&?]([\w=]*))*/g,
			`<iframe src="https://www.youtube.com/embed/$1?$3${params.get("t") ? `&start=${parseInt(params.get("t"), 10)}` : ""}" frameborder="0" allowfullscreen></iframe>`
		);
	}

	if (content.includes("medal.tv")) {
		// https://medal.tv/games/gta-v/clips/IaIBtZofA4Uxm/oyuXYKTqLJIx?theater=true
		// https://medal.tv/games/gta-v/clips/I6JGeupZPRpI4/d13379Z1g0Ly?invite=cr-MSxVZlgsODA0NzEyMDgs
		
		content = content.replace(
			/((?:https:\/\/)?(?:www\.)?(?:medal\.tv)\/)(.*)\/(.*)\/(.*)\/(.*)/g,
			`<iframe width='640' height='360' style='border: none;' src='https://medal.tv/$2/clip/$4/?mobilebypass=true' allow='autoplay' allowfullscreen></iframe>`
		);
	}

	if (content.includes("clips.twitch.tv")) {
		// https://clips.twitch.tv/TriangularBlindingWolfDoritosChip-BMThtPtBS6LCFy2F
		
		content = content.replace(
			/((?:https:\/\/)?(?:www\.)?(?:clips.twitch\.tv)\/)(.*)/g,
			`<iframe src="https://clips.twitch.tv/embed?clip=$2&parent=${window.location.hostname}" frameborder="0" allowfullscreen="true" scrolling="no" height="378" width="620"></iframe>`
		);
	}

	if (content.includes("streamable.com")) {
		// https://streamable.com/q32246
		
		content = content.replace(
			/(https:\/\/streamable.com\/(\w*))/g,
			`<div style="width: 100%; height: 0px; position: relative; padding-bottom: 56.250%;"><iframe src="https://streamable.com/e/$2?loop=0" frameborder="0" width="100%" height="100%" allowfullscreen style = "width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; overflow: hidden;"></iframe></div>`
		);
	}

	// https://gyazo.com/b76037ecd6a286ae734644d13b5c2756 -> https://gyazo.com/b76037ecd6a286ae734644d13b5c2756.png
	if (content.includes("i.gyazo.com")) {
		content = content.replace(
			/((?:https:\/\/)?(?:www\.)?(?:i.gyazo.com)\/([\w.-_]*))/g,
			'<a href="$1" target="_blank" rel="noopener noreferrer"><img src="$1"></a>'
		)
	} else if (content.includes("gyazo.com")) {
		content = content.replace(
			/(https:\/\/gyazo\.com\/[\d|\w]+)/g,
			'$1.png'
		)
	} else if (content.includes("imgur.com/a")) {
		content = content.replace(
			/(https:\/\/imgur\.com\/a\/[\d|\w]+)/g,
			'<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
		)
	} else if (content.includes("cdn.discordapp.com/attachments")) {
    content = content.replace(
			/(https:\/\/cdn\.discordapp\.com\/attachments\/\d+\/\d+\/(.+?)\.(?:png|jpg|jpeg|gif|bmp|svg|PNG|JPG|JPEG|GIF|BMP|SVG)\?.*?)(?=\s|$)/g,
			'<a href="$1" target="_blank" rel="noopener noreferrer"><img src="$1"></a>'
	);
	} else {
		// http://localhost:4242/players/0bd7e73faf082be2eee03b7d4978975fd4dff6e4/record
		const regex = new RegExp("(https?:\/\/.*\.(?:png|jpg|jpeg|PNG|JPG|JPEG))(.*)")
		if (regex.test(content) && !content.includes("<a ")) {
			content = content.replace(
				/(https?:\/\/.*\.(?:png|jpg|jpeg|PNG|JPG|JPEG))[^ \n\r]*/g,
				'<a href="$1" target="_blank" rel="noopener noreferrer"><img src="$1"></a>'
			)
		}
	}

	// gyazo mp4: http://localhost:4242/players/7406705c73dde5e5dd58075f97242add19af94a4/record
	content = content.replace(
		/(https?:\/\/.*\.(?:mp4))/g,
		'<video src="$1" controls>($1)'
	)

	content = content.replace(
		/(https?:\/\/.*\.(?:mp3))/g,
		`<audio controls><source src="$1" type="audio/mp3"></audio>`
	)

	content = content.replace(
		/(https?:\/\/.*\.(?:ogg))/g,
		`<audio controls><source src="$1" type="audio/ogg"></audio>`
	)

	//content = content.replace(/(?<!<\/?\w+[^>]*?)\b(https?:\/\/\S+)/g, `<a onclick="return confirm('Attention ! Ce lien peut être dangereux. Ne cliquez que si vous êtes sûr de sa destination.')" target=_blank href="$1"><u>$1</u></a>`);

	const textHasVideo = content.includes("<video")
	const textHasImage = content.includes("<img")
	const textHasLink = content.includes("<link")
	const textHasSound = content.includes("<audio")
	const textHasYoutubeVideo = content.includes("youtube.com/embed")

	const htmlTagRegex = /<.+?>/

	const textHasRawContent = !htmlTagRegex.test(content)
	if (!textHasRawContent) {
		content = content.trim()
	}

	return { 
		textHasRawContent,

		textHasVideo,
		textHasImage,
		textHasLink,
		textHasSound,
		textHasYoutubeVideo,
		content,
		originalContent,
	}
}

export default formatHtmlContent