import Immutable from "immutable"
import { createModule } from 'reacticoon/module'
import { createSelector, getStateForModule } from 'reacticoon/selector'
import { createAction } from 'reacticoon/action'
import { createReducer } from 'reacticoon/reducer'

// function getDevMessages() {
//   return Immutable.fromJS([
//     {
//       id: 'TICKET_MESSAGE_RECEIVED_867',
//       notificationType: 'TICKET_MESSAGE_RECEIVED',
//       duration: 2000,
//       data: {
//         lastFeed: {
//           fromType: 'CREATOR',
//           ticketId: 49,
//           author: '97fe98770553d5358defe87f4932a90e440169b2',
//           authorPlayer: {
//             identifier: '97fe98770553d5358defe87f4932a90e440169b2',
//             firstname: 'Loic',
//             lastname: 'Berry',
//             fullName: 'Loic Berry'
//           },
//           updatedAt: '2022-04-03T15:22:51.000Z',
//           previousVersions: [],
//           discordMessageId: '960197699129458708',
//           deleted: false,
//           content: '1',
//           id: 867,
//           createdAt: '2022-04-03T15:22:51.000Z'
//         }
//       }
//     },
//     {
//       id: 'TICKET_MESSAGE_RECEIVED_868',
//       notificationType: 'TICKET_MESSAGE_RECEIVED',
//       duration: 2000,
//       data: {
//         lastFeed: {
//           fromType: 'CREATOR',
//           ticketId: 49,
//           author: '97fe98770553d5358defe87f4932a90e440169b2',
//           authorPlayer: {
//             identifier: '97fe98770553d5358defe87f4932a90e440169b2',
//             firstname: 'Loic',
//             lastname: 'Berry',
//             fullName: 'Loic Berry'
//           },
//           updatedAt: '2022-04-03T15:22:51.000Z',
//           previousVersions: [],
//           discordMessageId: '960197700555518064',
//           deleted: false,
//           content: '2',
//           id: 868,
//           createdAt: '2022-04-03T15:22:51.000Z'
//         }
//       }
//     },
//     {
//       id: 'TICKET_MESSAGE_RECEIVED_869',
//       notificationType: 'TICKET_MESSAGE_RECEIVED',
//       duration: 2000,
//       data: {
//         lastFeed: {
//           fromType: 'CREATOR',
//           ticketId: 49,
//           author: '97fe98770553d5358defe87f4932a90e440169b2',
//           authorPlayer: {
//             identifier: '97fe98770553d5358defe87f4932a90e440169b2',
//             firstname: 'Loic',
//             lastname: 'Berry',
//             fullName: 'Loic Berry'
//           },
//           updatedAt: '2022-04-03T15:22:51.000Z',
//           previousVersions: [],
//           discordMessageId: '960197702023532664',
//           deleted: false,
//           content: '3',
//           id: 869,
//           createdAt: '2022-04-03T15:22:51.000Z'
//         }
//       }
//     },
//     {
//       id: 'TICKET_MESSAGE_RECEIVED_870',
//       notificationType: 'TICKET_MESSAGE_RECEIVED',
//       duration: 2000,
//       count: 4,
//       data: {
//         lastFeed: {
//           fromType: 'CREATOR',
//           ticketId: 49,
//           author: '97fe98770553d5358defe87f4932a90e440169b2',
//           authorPlayer: {
//             identifier: '97fe98770553d5358defe87f4932a90e440169b2',
//             firstname: 'Loic',
//             lastname: 'Berry',
//             fullName: 'Loic Berry'
//           },
//           updatedAt: '2022-04-03T15:22:52.000Z',
//           previousVersions: [],
//           discordMessageId: '960197704993079418',
//           deleted: false,
//           content: '4',
//           id: 870,
//           createdAt: '2022-04-03T15:22:52.000Z'
//         }
//       }
//     }
//   ])
// }

export const NotificationType = {
  TICKET_MESSAGE_RECEIVED: 'TICKET_MESSAGE_RECEIVED',
}

//
// we create an action, that receive a single argument `totoValue`
//
export const updateNotifications = createAction('NotificationModule:updateNotifications', (payload) => payload)
export const addNotification = createAction('NotificationModule:addNotification', (payload) => payload)
export const removeNotification = createAction('NotificationModule:removeNotification', (notificationId) => ({ notificationId }))

const getState = getStateForModule('App::NotificationModule')

export const getNotificationMessages = createSelector([getState], data => {
  const messages = data.get('messages')?.toJS() || null
  return messages
})

export const getLastSeenMessages = createSelector([getState], data => data.lastSeen)

function isSameTicket(notification, m) {
  return m.data.lastFeed.ticketId === notification.data.lastFeed.ticketId
}

const reducer = createReducer({
  // TODO:
  messages: null,
  // messages: null || getDevMessages(),
  lastSeen: null,
}, {
  [updateNotifications]: (state, action) => state.merge(action.payload),
  [addNotification]: (state, action) => {
    let messages = state.get('messages')?.toJS() || []
    const notification = {
      ...action.payload
    }
    
    if (action.payload.notificationType === NotificationType.TICKET_MESSAGE_RECEIVED) {
      // watcher, merge notifications for same ticket
      const existingNotification = messages.some(m => isSameTicket(notification, m))
      if (existingNotification) {
        // clean old notifications
        messages = messages.filter(m => !isSameTicket(notification, m))
        notification.count = (notification.count || 0) + 1
      }
    }
    
    messages.push(notification)
    return state.merge({ messages: Immutable.fromJS(messages) })
  },
  [removeNotification]: (state, action) => {
    const currentMessages = state.get('messages')?.toJS() || []
    return state.merge({ messages: Immutable.fromJS(currentMessages.filter(m => m.id !== action.payload.notificationId)) })
  },
})

const NotificationsModule = createModule('App::NotificationModule', {
  reducer,
  actions: {
    updateNotifications,
  }
})

export default NotificationsModule
