import { createMiddleware } from 'reacticoon/middleware'
import { redirectTo } from 'reacticoon/routing'
import { saveSession } from 'modules/auth/config'
import { postRegister } from './actions'

export const onRegisterSucceedMiddleware = createMiddleware(
  'App::RegisterModule::onRegisterSucceedMiddleware',
  postRegister.SUCCESS,
  ({ action, dispatch }) => {
    saveSession(action.response.token)
    dispatch(redirectTo('REGISTER_STEP_2'))
  }
)
