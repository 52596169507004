import React from 'react'

import { FlashMessageType } from 'reacticoon-plugin-flash-messages/service'
// https://github.com/iamhosseindhv/notistack
import { SnackbarProvider, useSnackbar } from 'notistack'
import FlashMessagesContainer from 'reacticoon-plugin-flash-messages/container'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    minWidth: 344,
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  typeError: {
    backgroundColor: theme.status.error,
  },
  typeSuccess: {
    backgroundColor: theme.status.valid,
  },
  icons: {
    marginLeft: 'auto',
    display: 'flex',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}))

const FlashMessages = React.memo(({ flashMessages }) => {
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    flashMessages.forEach((flashMessage, index) => {
      // const event = flashMessage.data

      const persist = false

      enqueueSnackbar(flashMessage, {
        key: flashMessage.id,
        variant: flashMessage.type,
        autoHideDuration: flashMessage.duration,
        persist,
        preventDuplicate: true,
      })
    })
  }, [flashMessages, enqueueSnackbar])

  return null
})

const ErrorDetail = ({ error }) => {
  let finalMessage
  if (!isNil(error)) {
    if (!isString(error)) {
      finalMessage = isEmpty(get(error, 'message'))
        ? get(error, 'error.message', null)
        : !isEmpty(get(error, 'apiError.message', null))
        ? get(error, 'apiError.message', null)
        : error.message
    } else {
      finalMessage = error || 'Erreur inconnue'
    }
  }

  return finalMessage
}

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleDismiss = () => {
    closeSnackbar(props.id)
  }

  const { flashMessage } = props
  const event = flashMessage.data

  const isEventException = event && event.type === 'REACTICOON::LOG::EXCEPTION' // TODO: use constant

  const isEventError = event && (event.code || event.message || event.apiError) 
  const displayDetail = isEventError || isEventException

  return (
    <Card className={clsx(classes.card)} ref={ref}>
      <CardActions
        classes={{
          root: clsx(classes.actionRoot, {
            [classes.typeError]:
              flashMessage.type === FlashMessageType.ERROR || isEventError || isEventException,
            [classes.typeSuccess]: flashMessage.type === FlashMessageType.SUCCESS,
          }),
        }}
      >
        <Typography variant="subtitle2" className={classes.typography}>
          {isEventException ? `Exception ${event.data.exceptionMessage}` : flashMessage.text}
        </Typography>
        <div className={classes.icons}>
          {displayDetail && (
            <IconButton
              aria-label="Show more"
              className={clsx(classes.expand, { [classes.expandOpen]: expanded })}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
          <IconButton className={classes.expand} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardActions>
      {displayDetail && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            {isEventError && <ErrorDetail error={event} />}
            {isEventException && (
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {event.data.exceptionStack}
              </pre>
            )}
          </Paper>
        </Collapse>
      )}
    </Card>
  )
})

const FlashMessagesView = () => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    content={(key, flashMessage) => <SnackMessage key={key} id={key} flashMessage={flashMessage} />}
  >
    <FlashMessagesContainer>
      {({ flashMessages }) => <FlashMessages flashMessages={flashMessages} />}
    </FlashMessagesContainer>
  </SnackbarProvider>
)

export default FlashMessagesView
