import React from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

const styles = (theme) => ({
  loadingBlockContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    // minHeight: 50,
  },
  loadingBlock: {
    left: 0,
    top: 0,
    zIndex: theme.zIndex.loader || 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  withBackground: {
    backgroundColor: theme.isDark ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.54)',
  },
  circularProgress: {},
  wrapperBox: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    position: 'relative',
  },
})

const LoadingBlock = ({
  size = 50,
  show = false,
  withBackground = false,
  children = null,
  withBox = false,
  classes,
  color,
}) => {
  if (!show) {
    // it happens that the children need data to be rendered. This data is not available while the
    // loading is made.
    // But even if we don't display the children here because we are showing the loading, the children
    // is build
    // Passing the children as a function avoid to re-check the conditions to render it, that is mostly
    // the same condition pass to the `show` prop.
    const view = isFunction(children) ? children() : children
    if (withBox) {
      return <Box className={classes.wrapperBox}>{view}</Box>
    } else {
      return view
    }
  }

  let finalSize = size
  if (finalSize === 'small') {
    finalSize = 30
  }

  return (
    <div className={classes.loadingBlockContainer} style={{ minHeight: finalSize }}>
      <div className={clsx(classes.loadingBlock, { [classes.withBackground]: withBackground })}>
        <CircularProgress color={color} size={finalSize} className={classes.circularProgress} />
      </div>
    </div>
  )
}

LoadingBlock.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  show: PropTypes.bool,
}

export default withStyles(styles)(LoadingBlock)
