import { createFormatter, createListFormatter, createSubObjectFormatter } from 'reacticoon/format'

import moment from 'moment'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import { findOnArray } from 'reacticoon/utils/array'
import { formatPrice } from 'modules/currency/format'
import { formatUser } from 'modules/user/format'
import { getJobData } from 'modules/jobGrades/constants'
import { millisecondsToStr } from 'modules/date'
import { formatPlayerAndRconPlayer } from 'modules/playerAndRconPlayer/format'
import { getGroupLabel, Group } from './constants'
import { formatInventory, formatInventoryInventory } from 'modules/inventory/format'

const formatBill = bill => {
  bill.amountFormatted = formatPrice(bill.amount)
  bill.dateFormatted = moment(bill.insertTime).format('DD/MM/YYYY HH:mm')

  return bill
}

const formatRefund = refund => {
  refund.amountFormatted = formatPrice(refund.amount)
  refund.dateFormatted = moment(refund.createdAt).format('DD/MM/YYYY HH:mm')
  return refund
}

const formatLicense = license => {
  license.dateFormatted = moment(license.insertTime).format('DD/MM/YYYY HH:mm')
  license.expirationFormatted = license.expiration ? moment(license.expiration).format('DD/MM/YYYY HH:mm') : "-"
  return license
}

const formatPlayerBan = (ban) => {
  ban.isPermanent = ban.permanent
  ban.expirationFormatted = moment(ban.expiration).format('DD/MM/YYYY HH:mm')
  ban.isBanByConsole = ban.banner?.fullName === 'Console'
  ban.explaination = `Banni ${ban.isBanByConsole ? 'automatiquement' : `par ${ban.banner?.fullName}`
    }${' '}
                          ${ban.isPermanent
      ? 'de manière permanente'
      : `jusqu'au ${ban.expirationFormatted}`
    }${' '}
                          pour la raison: ${ban.reason}`
  return ban
}

export const formatPlayer = createFormatter(
  player => {
    // TRICK: bad response from the API if the player has never logged in
    if (Array.isArray(player) && player.length === 0) {
      return {
        hasNeverLoggedIn: true,
        isBan: true,
      }
    }

    return player
  },
  formatPlayerAndRconPlayer,
  (player, { jobs: jobsLegacyParam, publicVariables : publicVariablesParam }) => {
    const publicVariables = publicVariablesParam || {
      weapons: [],
      items: [],
      jobs: jobsLegacyParam || [],
    }
    // FIXME: better fix
    const jobs = publicVariables?.jobs || jobsLegacyParam

    if (!player) {
      return null
    }
    player.id = player.identifier

    player.groupLabel = getGroupLabel(player.group)

    player.spyJoinDateFormatted = null
    if (player.spyJoinDate && player.insertTime) {
      player.spyJoinDateFormatted = player.spyJoinDate < player.insertTime ? moment(player.spyJoinDate).format('DD/MM/YYYY HH:mm') : moment(player.insertTime).format('DD/MM/YYYY HH:mm')
    } else if (player.spyJoinDate) {
      player.spyJoinDateFormatted = moment(player.spyJoinDate).format('DD/MM/YYYY HH:mm')
    } else if (player.insertTime) {
      player.spyJoinDateFormatted = moment(player.insertTime).format('DD/MM/YYYY HH:mm')
    }

    player.spyLastSeenFormmated = null
    if (player.spyLastSeen) {
      player.spyLastSeenFormmated = moment(player.spyLastSeen).format('DD/MM/YYYY HH:mm')
    } else if (player.lastSeen) {
      player.spyLastSeenFormmated = moment(player.lastSeen).format('DD/MM/YYYY HH:mm')
    }
    
    player.isGroupUser = player.group === Group.USER
    player.isGroupSupport = player.group === Group.SUPPORT
    player.isGroupMod = player.group === Group.MOD
    player.isGroupSupermod = player.group === Group.SUPERMOD
    player.isGroupStaff = player.group === Group.STAFF
    player.isGroupAdmin = player.group === Group.ADMIN

    player.isDead = player.isDead === 1 || player.isDead === true
    player.isAlive = !player.isDead
    player.lifeStatus = player.isDead ? 'Mort' : 'Vivant'

    // min to ms
    player.hasJailTime = player.jailTime > 0
    player.jailTimeFormatted = millisecondsToStr(player.jailTime * 1000)

    player.loggedInStatusLabel = player.isInGame ? 'Connecté' : 'Déconnecté'
    player.donatorLvlLabel = player.donatorlvl ? `Donateur ${player.donatorlvl}` : 'Pas donateur'

    player.firstName = player.firstname || ''
    player.lastName = player.lastname || ''
    player.fullName = player.fullName = player.fullName || `${player.firstName} ${player.lastName}`

    // m / f
    player.isFemale = player.sex !== 'm'
    player.isMale = player.sex === 'm'
    player.genderLabel = player.sex === 'm' ? 'Homme' : 'Femme'

    // min to ms
    player.timePlayedFormatted = millisecondsToStr(player.timePlayed * 60000)

    player.ips = player.ips || []

    player.avatarLabel = `${player.firstName[0]}${player.lastName[0]}`
    if (player.isFemale) {
      player.avatarUrl = `https://avatars.dicebear.com/api/female/${player.firstName}${player.lastName}.svg`
    } else {
      player.avatarUrl = `https://avatars.dicebear.com/api/male/${player.firstName}${player.lastName}.svg`
    }
    player.accounts = {
      blackMoney: player.accounts?.blackMoney || 0,
      blackMoneyFormatted: formatPrice(player.accounts?.blackMoney),

      money: player.accounts?.money || 0,
      moneyFormatted: formatPrice(player.accounts?.money),

      bank: player.accounts?.bank || 0,
      bankFormatted: formatPrice(player.accounts?.bank),

      chips: player.accounts?.chips || 0,
    }

    player.totalMoneyFormatted = formatPrice(player.totalMoney)

    player.jobData = getJobData(jobs, player.job, player.jobGrade)
    player.jobLabel = player.jobData?.job?.label || player.job || 'Aucun'
    player.jobGradeLabel = player.jobData?.label || player.jobGrade || 'Aucun'

    player.job2Data = getJobData(jobs, player.job2, player.job2Grade)
    player.job2Label = player.job2Data?.job?.label || player.job2 || 'Aucun'
    player.job2GradeLabel = player.job2Data?.label || player.job2Grade || 'Aucun'

    player.jobNames = [player.job, player.job2].filter(Boolean)

    const UNKNOWN_PERCENT = ' - '
    const NONE_PERCENT = '0'
    const DEFAULT_STATUS_DATA = {
      percent: UNKNOWN_PERCENT,
    }

    const currTime = Math.floor(Date.now() / 1000);
    let currentPtsdStatusValue = {
      percent: NONE_PERCENT
    }
    const currentPtsdStatus = findOnArray(player.status, (status) => status.name === "ptsd");
    if (currentPtsdStatus && (!currentPtsdStatus.timeout || (currentPtsdStatus.timeout > currTime))) {
      currentPtsdStatusValue = currentPtsdStatus
    }

    player.statusData = {
      hunger:
        findOnArray(player.status, (status) => status.name === 'hunger') || DEFAULT_STATUS_DATA,
      thirst:
        findOnArray(player.status, (status) => status.name === 'thirst') || DEFAULT_STATUS_DATA,
      drunk: findOnArray(player.status, (status) => status.name === 'drunk') || DEFAULT_STATUS_DATA,
      armour:
        findOnArray(player.status, (status) => status.name === 'armour') || DEFAULT_STATUS_DATA,
      health:
        findOnArray(player.status, (status) => status.name === 'health') || DEFAULT_STATUS_DATA,
      ptsd: currentPtsdStatusValue
    }

    function formatStatusData(varName) {
      if (
        player.statusData[varName]?.percent &&
        player.statusData[varName].percent !== UNKNOWN_PERCENT
      ) {
        player.statusData[varName].percent = !isString(player.statusData[varName].percent)
          ? Math.ceil(Number(player.statusData[varName].percent)).toFixed(0)
          : player.statusData[varName].percent
      }
    }

    formatStatusData('hunger')
    formatStatusData('thirst')
    formatStatusData('drunk')
    formatStatusData('armour')
    formatStatusData('health')
    formatStatusData('ptsd')

    // transform object into array
    player.loadoutData = map(player.loadout || {}, (data, name) => {
      return {
        name,
        ammo: data.ammo,
        data: {
          sn: data.data.sn,
          durability: data.data.durability
        },
        ...((publicVariables.weapons || []).find((item) => item.name === name.replace("wEAPON", "WEAPON_").toUpperCase()) || {}),
      }
    })
    player.loadoutData = sortBy(player.loadoutData, i => i.label?.toLowerCase())

    // transform object into array

    const inventoryData = []
    forEach(player.inventory, formatInventoryInventory(publicVariables, inventoryData))
    player.inventoryData = inventoryData
  
    player.inventoryData = sortBy(player.inventoryData, i => i.label?.toLowerCase())
    player.hasBag = !!player.bagInventory

    // TODO: use inventory formatter
    // format data of the player to be like the other inventories
    player.inventory = {
      inventoryData: player.inventoryData,
      loadoutData: player.loadoutData,

      ...player.accounts,
    }

    if (player.hasBag) {
      player.bagInventory.content.inventory = map(player.bagInventory.content.inventory || {}, (data, name) => {
        const actualName = typeof data === 'object' && "name" in data ? data.name : name;
        const item = {
          actualName,
          label: data.label,
          count: data.count,
          ...((publicVariables.items || []).find((item) => item.name === actualName) || {}),
        }
        item.originalLabel = item.label
        item.label = item.label || item.name
        item.hasCustomLabel = item.label !== item.originalLabel
        return item
      })
      player.bagInventory.content.name =  player.bagInventory.name
      player.bagInventory.content.inventory = sortBy(player.bagInventory.content.inventory, i => i.label?.toLowerCase())
      player.bagInventory.moneyFormatted = formatPrice(player.bagInventory.money || 0)
      player.bagInventory.blackMoneyFormatted = formatPrice(player.bagInventory.blackMoney || 0)
    }

    // transform object into array
    // player.skinData = map(player.skin || {}, (value, id) => {
    //   return {
    //     id,
    //     value,
    //   }
    // })

    player.billsTotalAmountFormatted = formatPrice(player.bills?.reduce(
      (previousValue, currentValue) => previousValue + currentValue.amount,
      0
    ));
    player.hasBills = !isEmpty(player.bills)
    player.nonRadarBills = player.bills?.filter(e => !e.isRadar);
    player.radarBills = player.bills?.filter(e => e.isRadar);

    player.radarBillsTotalAmountFormatted = formatPrice(player.radarBills?.reduce(
      (previousValue, currentValue) => previousValue + currentValue.amount,
      0
    ));

    player.hasRefunds = !isEmpty(player.refunds)
    player.hasUser = !!player.user
    return player
  },
  createSubObjectFormatter('user', formatUser),
  createSubObjectFormatter('ban', formatPlayerBan),
  createSubObjectFormatter('bills', createListFormatter(formatBill)),
  createSubObjectFormatter('refunds', createListFormatter(formatRefund)),
  createSubObjectFormatter('licenses', createListFormatter(formatLicense)),

  createSubObjectFormatter('properties', createListFormatter((property, { publicVariables }) => {
    property.label = property.propertyId
    property.type = property.propertyType
    if (!property.inventories) {
      property.inventories = {}
    }

    property.inventories = map(property.inventories, (inventory) => {
      inventory = formatInventory(inventory, { publicVariables })
      return inventory
    })

    property.mailbox = formatInventory(property.mailbox, { publicVariables })
    return property
  })),

  createSubObjectFormatter('bagInventory', createFormatter((prop, { publicVariables }) => {
    prop.content = formatInventory(prop.content, { publicVariables })
    return prop
  })),
  createSubObjectFormatter('propsInventories', createListFormatter((prop, { publicVariables }) => {
    prop.content = formatInventory(prop.content, { publicVariables })
    return prop
  })),
  createSubObjectFormatter('donatorFarmsInventories', createListFormatter((prop, { publicVariables }) => {
    prop.content = formatInventory(prop.content, { publicVariables })
    return prop
  })),
  createSubObjectFormatter('motelInvInventories', createListFormatter((prop, { publicVariables }) => {
    prop.content = formatInventory(prop.content, { publicVariables })
    return prop
  })),
  createSubObjectFormatter('lockerInventories', createListFormatter((prop, { publicVariables }) => {
    prop.content = formatInventory(prop.content, { publicVariables })
    return prop
  })),

  createSubObjectFormatter('housesInventoryItems', createListFormatter((item, { publicVariables }) => {
    return {
      ...item,
      quantity: item.amount ?? item.count,
      ...((publicVariables.items || []).find((i) => i.name === item.name) || {}),
    }
  }))
)
