import React from 'react'
import { createModuleContainer } from 'reacticoon/container'

import AuthModule from 'modules/auth'
import AuthContext from './context'

const AuthModuleContainer = createModuleContainer('App::AuthModule::AuthContainer', AuthModule, {
  selectors: {
    session: 'getSession',
  },
  actions: [],
})

const AuthContainer = ({ children }) => (
  <AuthModuleContainer>
    {({ session }) => <AuthContext.Provider value={session}>{children}</AuthContext.Provider>}
  </AuthModuleContainer>
)

export default AuthContainer
