import { useCallback } from "react"
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import CommandModule from 'modules/command'

import useMountEffect from 'reacticoon/view/hook/useMountEffect'
import { createSelectors } from 'modules/command/selectors'
import { fetchPublicVariablesListSelectors } from 'modules/publicVariables/selectors'

const cache = {}
function getSelectors(command) {
  if (cache[command]) {
    return cache[command]
  }

  const selectors = createSelectors(command)
  cache[command] = {
    isPending: selectors.makeIsPending(),
    // data: selectors.makeGetCommandData(),
    data: selectors.makeGetData(),
    error: selectors.makeGetError(),
    request: selectors.makeGetRequest(),
  }
  return cache[command]
}

const useCommand = ({
  command,
  manualRun,
  // handle pending on view, not automatically on the LoadingContainer
  manualPending = false,
  onUpdate,
  onSuccess,
  onFailure,
  formatter,
  devResponse,
  id = '_',
  payload,
  queryParams = {},
  resetDataOnMount = false,
  paginateCommand = false,
  autoReset = false,
  keepDataOnRequest = false,
  formatterProps = {},
  withCache = false // TODO:
}) => {
  const dispatch = useDispatch()


  const selectors = getSelectors(command)
  const getDataSelector = selectors.data
  const isPendingSelector = selectors.isPending
  const getErrorSelector = selectors.error
  const getRequestSelector = selectors.request

  const publicVariables = useSelector(fetchPublicVariablesListSelectors.getData) || {}

  const applyFormatter = (res) => {
    const data = !paginateCommand ? res : res?.data
    // trick to not use getCommandData
    if (data && formatter) {
      return formatter(data, { publicVariables, ...formatterProps })
    } else {
      return data
    }
  }

  const allData = useSelector((state) => {
    const res = getDataSelector(state, { command, id, formatter })

    const paging = res?.paging || null
    const data = applyFormatter(res)
    return { data, paging }
  }, shallowEqual)

  const isPending = useSelector((state) => {
    const res = isPendingSelector(state, { command, id })
    return res
  }, shallowEqual)

  const error = useSelector((state) => {
    const res = getErrorSelector(state, { command, id })
    return res
  }, shallowEqual)

  const request = useSelector((state) => {
    const res = getRequestSelector(state, { command, id })
    return res
  }, shallowEqual)


  const resetRequestData = (where) =>
    dispatch(CommandModule.getAction('resetRequestData')(id, command, where))

  const onSuccessCallback = useCallback((...data) => {
    // TODO: send paging as second arg.
    onSuccess && onSuccess.apply(null, applyFormatter(data))
    if (autoReset) {
      resetRequestData('on success')
    }
  }, [onSuccess, autoReset, resetRequestData])

  const onFailureCallback = useCallback((...data) => {
    onFailure && onFailure.apply(null, data)

    if (autoReset) {
      resetRequestData('on failure')
    }
  }, [onSuccess, autoReset, resetRequestData])

  const runCommand = useCallback((payloadParam, { data } = {}) =>
    dispatch(
      CommandModule.getAction('runCommand')(id, command, {
        queryParams,
        payload: payloadParam || payload,
        keepDataOnRequest: keepDataOnRequest || paginateCommand,
        paginateCommand: paginateCommand,
        data: data,
        devResponse: devResponse,
        onSuccess: onSuccessCallback,
        onFailure: onFailureCallback,
      })
    ), [id, command, payload, keepDataOnRequest, paginateCommand, devResponse, dispatch, queryParams, onSuccessCallback, onFailureCallback])

  const setData = (data) => dispatch(CommandModule.getAction('setData')(id, command, data))

  useMountEffect(() => {
    if (resetDataOnMount) {
      resetRequestData()
    }
    if (!manualRun) {
      runCommand(payload)
    }
  })

  return {
    runCommand,
    resetRequestData,
    setData,
    loadMore: () => {
      runCommand({
        ...(payload || {}),
        page: allData?.paging?.next?.page,
      })
    },
    data: allData?.data,
    paging: allData?.paging,
    isPending,
    error,
    request,
  }
}

export default useCommand