import { createModule } from 'reacticoon/module'

import * as actions from './actions'
import * as middlewares from './middlewares'
import { loginSelectors } from './selectors'
import reducer from './reducer'

const LoginModule = createModule('App::LoginModule', {
  actions,
  reducer,
  selectors: loginSelectors,
  middlewares,
})

export default LoginModule
