const isArray = require('lodash/isArray')
const isNil = require('lodash/isNil')
const isEmpty = require('lodash/isEmpty')

/**
 * @param permissionsParam can either be an array of permissions, or a permission string.
 * @param permissionsParam The permission to check. Example: 'api.user.canEdit..'
 * @param permissions The permission will be checked for the given permissions.  
 * 
 * Multiples permissions: has permission if one of the permissions is good.
 */
const hasPermissions = (permissionsParam, userPermissions = null) => {
  let permissions = []
  if (!isArray(permissionsParam)) {
    permissions = [permissionsParam]
  } else {
    permissions = permissionsParam
  }

  if (isEmpty(permissions)) {
    return false
  }

  return permissions.some((permission) => {
    try {
      const obj = permission.split('.').reduce((object, key) => {
        return object[key]
      }, userPermissions)

      /**
       * The permission is ok if the object equals true or is an object that contains child
       * permissions.
       */
      const hasPermission = !isNil(obj) && obj !== false

      return hasPermission
    } catch (e) {
      // do nothing
      return false
    }
  })
}

class SecurityManager {
  permissions = {}

  registerUser = user => {
    this.registerPermissions(user.permissions)
    this.player = user.gamePlayer
  }

  registerPermissions = (permissions) => {
    this.permissions = permissions
  }

  // permissions can either be an array of permissions, or a permission string.
  hasPermission = (permissionsToVerify = null, userPermissions) => {
    if (isEmpty(permissionsToVerify)) {
      return true
    }
    return hasPermissions(permissionsToVerify, userPermissions || this.permissions)
  }

  getPermissions = () => {
    return this.permissions
  }

  //
  // permissions shortcuts
  //

  hasCadPermission = (loggedInFaction, permission) => {
    return this.hasPermission(`cad.${loggedInFaction}${permission ? `.${permission}` : ''}`)
  }

}

export default new SecurityManager()
