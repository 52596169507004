const getTabsConfigRanking = () => ({
  tabs: [
    {
      label: 'Argent',
      route: 'RANKING_MONEY',
    },
    {
      label: 'Entreprises',
      route: 'RANKING_MONEY_JOB',
    },
    {
      label: 'Temps de jeu',
      route: 'RANKING_PLAYED_TIME',
    },
    {
      label: 'Amendes',
      route: 'RANKING_FEES',
    },
    {
      label: 'Classements',
      route: 'RANKING_LEADERBOARD',
    }
  ],
})

export default getTabsConfigRanking