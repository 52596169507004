import { getFromStorage } from 'reacticoon/storage'

import blue from '@material-ui/core/colors/blue'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import lightGreen from '@material-ui/core/colors/lightGreen'
import green from '@material-ui/core/colors/green'
import purple from '@material-ui/core/colors/purple'

const type = getFromStorage('MUI::THEME::TYPE') || 'dark'

const palette = {
  type,
  primary: { main: blue[500] },
  secondary: { main: '#11cb5f' },
}

const theme = {
  type,
  isDark: type === 'dark',
  palette,
  color: { 
    middlegrey: '#919ca7',
    lightgrey: '#eceff1', // efeff4
    red400: red[400],
    green400: green[400],
    purple300: purple[300],
  },
  status: {
    error: red[400],
    warning: orange[300],
    inactive: grey[500],
    valid: lightGreen[400],
    info: blue[700],
  },
  app: {
    background: {
      dark: '#2F2F2F',
      // dark: '#000c',
    },
    header: {
      height: '50px',
    },
    sidebar: {
      width: 250,
      color: '#000c', //darkBlack,
    },
    appBar: {
      height: '50px',
    },
 
    ticket: {
      sidebarWidth: 232,
    },
  },
  style: {
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    focusPrimary: {
      '&:focus': {
        backgroundColor: palette.primary.main,
        color: 'white',
      },
    },
    chip: {
      borderColor: palette.primary.main,
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      height: 20,
      lineHeight: 20,
      marginLeft: 5,
    },
    scrollbar: {
      '&::-webkit-scrollbar-corner': {
        background: 'rgba(0,0,0,0)',
      },

      '&::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        borderRadius: '10px',
        backgroundColor: '#424242' // palette.background.paper,
      },
  
      '&::-webkit-scrollbar': {
        width: '12px',
        backgroundColor: '#424242' // palette.background.paper,
      },
  
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
        backgroundColor: '#2F2F2F',
      },
    }
  },
  gta: {
    colors: {
      // https://www.schemecolor.com/grand-theft-auto-gta-v.php
      green: '#47761E',
      yellow: '#FED985',
      salmon: '#F09E71',
      purple: '#933CE5',
      blue: '#61B5CB',
      lightGreen: '#93B592',
      rose: ' #D5A0C4',
    }
  },
  typography: {
    fonts: {
      104: {
        style: {
          fontSize: 10,
          fontWeight: 400,
          margin: 0,
        },
      },
      124: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          margin: 0,
        },
      },
      134: {
        style: {
          fontSize: 13,
          fontWeight: 400,
          margin: 0,
        },
      },
      137: {
        style: {
          fontSize: 13,
          fontWeight: 700,
          margin: 0,
        },
      },
      144: {
        style: {
          fontSize: 14,
          fontWeight: 400,
          margin: 0,
        },
      },
      147: {
        style: {
          fontSize: 14,
          fontWeight: 700,
          margin: 0,
        },
      },
      164: {
        style: {
          fontSize: 16,
          fontWeight: 400,
          margin: 0,
        },
      },
      167: {
        style: {
          fontSize: 16,
          fontWeight: 700,
          margin: 0,
        },
      },
      184: {
        style: {
          fontSize: 18,
          fontWeight: 400,
        },
      },
      187: {
        style: {
          fontSize: 18,
          fontWeight: 700,
          margin: 0,
        },
      },
      204: {
        style: {
          fontSize: 20,
          fontWeight: 400,
          margin: 0,
        },
      },
      207: {
        style: {
          fontSize: 20,
          fontWeight: 700,
          margin: 0,
        },
      },
      224: {
        style: {
          fontSize: 22,
          fontWeight: 400,
          margin: 0,
        },
      },
      240: {
        style: {
          fontSize: 24,
          fontWeight: 400,
          margin: 0,
        },
      },
      247: {
        style: {
          fontSize: 24,
          fontWeight: 700,
          margin: 0,
        },
      },
    },
  }
}

export default theme
