import cloneDeep from 'lodash/cloneDeep'
import {
  createApiObjectSelectors,
  createMakeSelector,
  getStateForModule,
} from 'reacticoon/selector'
import { fetchPublicVariablesListSelectors } from 'modules/publicVariables/selectors'

export const createSelectors = (command) => {
  // create the `getState` function, that will receive the state for the
  // given module.
  const getState = getStateForModule('App::CommandModule')

  const getPath = (_, props) => [command, props.id]

  const getFormatter = (_, props) => props.formatter || ((a) => a)

  const getFormatterProps = (_, props) => props.formatterProps || null

  const selectors = createApiObjectSelectors(getState, getPath)

  const makeGetCommandData = createMakeSelector(
    'getCommandData',
    () => [
      selectors.makeGetData(),
      fetchPublicVariablesListSelectors.getData,
      getFormatter,
      getFormatterProps,
    ],
    getPath,
    (data, publicVariables, formatter, formatterProps) => {
      // clone deep to avoid mutation state crash
      if (!data) {
        return data
      }
      if (data.paging) {
        data.data = formatter(cloneDeep(data.data), { publicVariables, ...formatterProps })
        return data
      }
      return formatter(cloneDeep(data), { publicVariables, ...formatterProps })
    }
  )

  return { ...selectors , makeGetCommandData }
}