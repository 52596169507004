import React from 'react'

const UserMeContext = React.createContext({
	userMe: null,
	setUserMe: () => {}
})

UserMeContext.displayName = "UserMeContext"

export default UserMeContext

