import React from 'react'

import { findOnArray } from 'reacticoon/utils/array'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const ListChooser = ({
  label,
  value = '',
  placeholder,
  options: optionParams = [],
  helperText,
  fullWidth,
  onChange,
  multiple = false,
  disabled = false,
  withoutFormControl = false,
  selectProps = {},
  ...otherOptions
}) => {
  const options = optionParams.filter(Boolean);
  const select = (
    <Select
      label={
        !multiple
          ? findOnArray(options, (option) => option.value === value)?.label || value
          : options
              .filter((option) => value.indexOf(option.value) !== -1)
              .map((option) => option.label || option.value)
              .join(', ')
      }
      value={value}
      multiple={multiple}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      renderValue={(selected) =>
        multiple
          ? (selected || [])
              .map((value) => findOnArray(options, (option) => option.value === value)?.label)
              .join(', ')
          : findOnArray(options, (option) => option.value === selected)?.label ||
            selected?.label ||
            selected
      }
      {...selectProps}
    >
      {options.filter(Boolean).map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {multiple && <Checkbox checked={value.indexOf(option.value) !== -1} />}
          <ListItemText>{option.label}</ListItemText>
        </MenuItem>
      ))}
    </Select>
  )

  return withoutFormControl ? (
    select
  ) : (
    <FormControl fullWidth={fullWidth} disabled={disabled} {...otherOptions}>
      <InputLabel>{label}</InputLabel>
      {select}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default ListChooser
