import { createMiddleware } from 'reacticoon/middleware'
import { redirectTo } from 'reacticoon/routing'
import { saveSession } from 'modules/auth/config'
import { postLogin } from './actions'

export const onLoginSucceedMiddleware = createMiddleware(
  'App::LoginModule::onLoginSucceedMiddleware',
  postLogin.SUCCESS,
  ({ action, dispatch }) => {
    saveSession(action.response.token)
    dispatch(redirectTo('DASHBOARD'))
  }
)