import React from 'react'

import useStorageState from 'reacticoon/view/hook/useStorageState'

const CadFactionContext = React.createContext({})

CadFactionContext.displayName = "CadFactionContext"

export function CadFactionProvider({ children }) {
  const [faction] = useStorageState('CAD_FACTION_SELECTED', null)

	return (
		<CadFactionContext.Provider value={{ loggedInFaction: faction }}>
			{children}
		</CadFactionContext.Provider>
	)
}

export default CadFactionContext