import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { getEnvVar } from 'reacticoon/environment'
import { getStore } from 'reacticoon/store'
import { getJwtToken } from 'modules/auth/config'
import { redirectTo } from 'reacticoon/routing'
import { getFrontSessionId, FRONT_SESSION_ID_HEADER } from 'modules/frontSession'

const ApiManagerOptions = () => ({
  apiUrl: getEnvVar('API_URL'),

  store: null,

  headersMiddleware: () => {
    const headers = {
      Accept: 'application/json',
    }

    const jwtToken = getJwtToken()
    if (!isEmpty(jwtToken)) {
      headers['Authorization'] = `${jwtToken}`
    }

    headers[FRONT_SESSION_ID_HEADER] = getFrontSessionId() 

    return headers
  },

  errorMiddleware: (error, res) => {
    if (isNil(res)) {
      return false
    }

    if (window.location.pathname !== "/logout") { 
      if (res.statusCode === 401) {
        console.error('[API] 401, redirect to logout')

        getStore().dispatch(redirectTo('LOGOUT'))
        return true
      }
    }

    // if (res.statusCode === 500) {
    //   Logger.error('API 500', res)
    //
    //   // TODO: redirect to error page
    //   return true
    // }

    return false
  },
})

export default ApiManagerOptions
