import { createFormatter } from 'reacticoon/format'

import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { formatPrice } from 'modules/currency/format'
import { deepClone } from 'fast-json-patch'

export const formatInventoryInventory = (publicVariables, inventoryData) => (data, name) => {
  const actualName = typeof data === 'object' && "name" in data ? data.name : name;
  const item = {
    actualName,
    ...((publicVariables.items || []).find((item) => item.name === actualName) || {}),
    ...data,
  }
  item.originalLabel = item.label || item.name
  item.label = item.data?.label || item.label || item.name
  item.hasCustomLabel = item.originalLabel !== item.label
  item.count = data.count || data

  // meta == different items
  if (!item.meta) {
    
    // specific to player inventory, which do not have a meta..
    item.meta = {}
    if (item.rawData) {
      // transform data to follow the 'meta' object we have on other inventories
      item.meta.rawData = item.rawData
      delete item.rawData
      item.meta.originalData = JSON.parse(item.meta.rawData)
    }

    inventoryData.push(item)
    return
  }

  let withoutMetaCount = item.count
  item.meta.forEach(m => {
    withoutMetaCount -= m.count
    const metaItem = deepClone(item)
    metaItem.count = m.count
    metaItem.meta = m
    metaItem.label = m.data?.label || item.label 
    metaItem.hasCustomLabel = item.label !== metaItem.label
    
    // API gives the meta has raw, because we camelize our data, and should not with the meta
    if (m.rawData) {
      m.originalData = JSON.parse(m.rawData)
      metaItem.originalData = m.originalData
    }

    inventoryData.push(metaItem)
  })

  if (withoutMetaCount > 0) {
    item.count = withoutMetaCount
    inventoryData.push(item)
  }

}

export const formatInventory = createFormatter((inventory, { publicVariables }) => {
  inventory.isEmpty = (isEmpty(inventory.inventory) && isEmpty(inventory.loadout) && inventory.money === 0 && inventory.chips === 0 && inventory.blackMoney === 0)

  inventory.moneyFormatted = formatPrice(inventory.money || 0)
  inventory.chipsFormatted = formatPrice(inventory.chips || 0, '')
  inventory.blackMoneyFormatted = formatPrice(inventory.blackMoney || 0)

  inventory.accountsData = [
    {
      label: 'Cash',
      accountType: 'money',
      amount: inventory.money || 0,
      amountFormmated: inventory.moneyFormatted
    },
    {
      label: 'Jetons',
      accountType: 'chips',
      amount: inventory.chips || 0,
      amountFormmated: inventory.chipsFormatted
    },
    {
      label: 'Argent sale',
      accountType: 'blackMoney',
      amount: inventory.blackMoney || 0,
      amountFormmated: inventory.blackMoneyFormatted
    },
  ]

  const inventoryData = []
  forEach(inventory.inventory, formatInventoryInventory(publicVariables, inventoryData))

  inventory.inventoryData = inventoryData
  inventory.inventoryData = sortBy(inventory.inventoryData, l => l.originalLabel?.toLowerCase())

  inventory.loadoutData = map(inventory.loadout, (data, id) => {
    const item = {
      id,
      ...data,
      label: data.name.replace("WEAPON_", ""),
      ...((publicVariables.weapons || []).find((item) => item.name === data.name) || {}),
    }

    // API gives the meta has raw, because we camelize our data, and should not with the meta
    if (item.rawData) {
      item.originalData = JSON.parse(item.rawData)
    }

    return item
  })
  inventory.loadoutData = sortBy(inventory.loadoutData, l => l.label?.toLowerCase())
  return inventory
})