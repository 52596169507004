import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Console = (props) => (
  <SvgIcon
    fill="currentColor"
    fillRule="evenodd"
    //height="100%"
    //width="50.000000pt"
    height="5 0.000000pt"
    viewBox="0 0 50.000000 50.000000"
    width="100%"
    fit=""
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    {...props}
  >
    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M7 464 c-4 -4 -7 -103 -7 -221 l0 -213 250 0 250 0 -2 218 -3 217
-241 3 c-132 1 -243 -1 -247 -4z m473 -39 l0 -25 -230 0 -230 0 0 25 0 25 230
0 230 0 0 -25z m0 -210 l0 -165 -230 0 -230 0 0 165 0 165 230 0 230 0 0 -165z"
      />
      <path
        d="M140 293 c0 -4 12 -20 27 -35 l27 -28 -29 -30 c-42 -44 -21 -51 22
-7 l37 37 -34 35 c-32 33 -50 43 -50 28z"
      />
      <path
        d="M220 170 c0 -6 32 -10 75 -10 43 0 75 4 75 10 0 6 -32 10 -75 10 -43
0 -75 -4 -75 -10z"
      />
    </g>
  </SvgIcon>
)

Console.displayName = 'Console'
Console.muiName = 'SvgIcon'

export default Console
