import { createApiCallAction } from 'reacticoon/action'

//
// Actions
//

export const fetchPublicVariablesList = createApiCallAction(
  'App::PublicVariables::fetchPublicVariablesList',
  (payload) => ({
    type: 'POST',
    endpoint: '/commands/GET_PUBLIC_VARIABLES',
    body: {
      command: 'GET_PUBLIC_VARIABLES',
      payload,
    },
  })
)
