import SecurityManager from 'modules/security/SecurityManager'

const getTabsConfigAdministration = () => ({
  tabs: [
    SecurityManager.hasPermission('api.administration.adminList') && {
      label: 'Staff',
      route: 'ADMIN_LIST'
    },
    SecurityManager.hasPermission('api.administration.history') && {
      label: 'Logs panel',
      route: 'TASE_LOGS'
    },
    SecurityManager.hasPermission('api.administration.settings') && {
      label: 'Paramètres',
      route: 'SETTINGS'
    }
  ],
})

export default getTabsConfigAdministration
