import React from 'react'
import TaseButton from 'components/TaseButton'
import LoadingBlockWrapper from './LoadingBlockWrapper'

const ButtonLoading = ({
  component = <TaseButton />,
  loadingSize,
  isLoading = false,
  isPending = false,
  size,
  children,
  ...otherProps
}) =>
  React.cloneElement(component, {
    children: (
      <React.Fragment>
        <LoadingBlockWrapper
          show={isLoading || isPending}
          size={loadingSize ? loadingSize : size === 'small' ? 24 : 40}
        />
        {children}
      </React.Fragment>
    ),
    size,
    ...otherProps,
  })

export default ButtonLoading
