function convertDiscordMarkdownToHTML(text) {
	// Replace bold markdown
	text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

	// Replace italic markdown
	text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');

	// Replace underline markdown
	text = text.replace(/__(.*?)__/g, '<u>$1</u>');

	// Replace strikethrough markdown
	text = text.replace(/~~(.*?)~~/g, '<del>$1</del>');

	// Replace code markdown
	text = text.replace(/```(.*?)```/gs, '<pre>$1</pre>');

	// Replace inline code block markdown
	text = text.replace(/`(.*?)`/g, '<code>$1</code>');

	// Replace spoiler markdown
	text = text.replace(/\|\|(.*?)\|\|/g, '<span class="spoiler">$1</span>');

	// Return converted HTML
	return text;
}

export default convertDiscordMarkdownToHTML