import React from 'react';
import clsx from "clsx"
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from "lodash/isEmpty"
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import {
  NotificationType,
  getNotificationMessages,
  getLastSeenMessages,
  updateNotifications,
  removeNotification,
} from "modules/notification"
import useRouting from "reacticoon/routing/useRouting"
import LinesEllipsis from 'react-lines-ellipsis'
import CloseIcon from '@material-ui/icons/Close'

const useTicketWatcherMenuNotificationStyles = makeStyles(theme => ({
	root: {
	},
  content: {
    fontSize: '13px',
    fontFamily: 'monospace',
    paddingLeft: theme.spacing(1),
  },
}))

function TicketWatcherMenuNotification({ notification, onRemove }) {
	const classes = useTicketWatcherMenuNotificationStyles()
	const { redirectTo } = useRouting()

  const lastFeed = notification.data.lastFeed

	return (
		<Notification
      notification={notification}
			onClick={() => {
				redirectTo('TICKET', { displayId: lastFeed.ticketDisplayId }, { message: lastFeed.discordMessageId })
			}}
      onRemove={onRemove}
			className={classes.root}
		>
			<div>#{lastFeed.ticketDisplayId} - <span>{lastFeed.authorDisplayName}</span></div>
			<LinesEllipsis className={classes.content} text={lastFeed.resume} maxLine="2"/>
		</Notification>
	)
}

const useNotificationStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: theme.spacing(1),

    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  left: {
    display: "flex",
    flexDirection: 'column',
    flex: 1,
  },
  right: {
    display: "flex",
    alignItems: 'center',
    // flexDirection: 'column',
  },
  notificationCount: {
    width: 'auto',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),

    '& .MuiBadge-root': {
      width: 16,
    },
    '& .MuiBadge-badge': {
      background: `#1565c0`,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(1),
    width: 14,
    height: 14,
    color: `#c3c3c3`,
  }
}))

function NotificationCount({ className, notification }) {
  if (notification.count > 0) {
    return (
      <div className={className}>
        <Badge badgeContent={notification.count} />
      </div>
    )
  }

  return null
}


function Notification({ children, onClick, className, onRemove, notification }) {
  const classes = useNotificationStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.left} onClick={onClick}>{children}</div>
      <div className={classes.right}>
				<NotificationCount notification={notification} className={classes.notificationCount} />
        <CloseIcon onClick={onRemove} className={classes.closeIcon} />
      </div>
    </div>
  )
}


function NotificationContent({ notification }) {
  const dispatch = useDispatch()

  function onRemove() {
    dispatch(removeNotification(notification.id))
  }

  if (notification.notificationType === NotificationType.TICKET_MESSAGE_RECEIVED) {
    return (
      <TicketWatcherMenuNotification notification={notification} onRemove={onRemove} />
    )
  }

  return (
    <Notification onRemove={onRemove} notification={notification}>
      <Typography gutterBottom>{notification.title}</Typography>
      <Typography gutterBottom variant="body2">
        <span
          dangerouslySetInnerHTML={{ __html: notification.text }}
        />
      </Typography>
      {notification.date && (
        <Typography variant="caption" color="textSecondary">
          {new Date(notification.date).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Typography>
      )}
    </Notification>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  paper: {
    transformOrigin: 'top right',
  },
  list: {
    width: theme.spacing(40),
    maxHeight: theme.spacing(40),
    overflow: 'auto',
  },
  listItem: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  noNotifications: {
    margin: 'auto',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
  },
  divider: {
    // margin: theme.spacing(1, 0),
  },
}));

export default function Notifications({ me }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const dispatch = useDispatch();
  const messages = useSelector(getNotificationMessages)
  const lastSeen = useSelector(getLastSeenMessages)

  const messageList = messages
    ? messages.reverse()
    : []; // TODO: null

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTooltipOpen(false);

    if (messageList && messageList.length > 0) {
      dispatch(updateNotifications({
        lastSeen: messageList[0].id,
      },
      ));
      document.cookie = `lastSeenNotification=${messageList[0].id};path=/;max-age=31536000`;
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        open={tooltipOpen}
        onOpen={() => {
          setTooltipOpen(!open);
        }}
        onClose={() => {
          setTooltipOpen(false);
        }}
        title={'Notifications'}
        enterDelay={300}
      >
        <IconButton
          color="inherit"
          ref={anchorRef}
          aria-controls={open ? 'notifications-popup' : undefined}
          aria-haspopup="true"
          aria-label={'Notifications'}
          onClick={handleToggle}
          data-ga-event-category="AppBar"
          data-ga-event-action="toggleNotifications"
          className={classes.button}
        >
          <Badge
            color="secondary"
            badgeContent={
              messageList
                ? messageList.reduce(
                    (count, message) => (message.id > lastSeen ? count + 1 : count),
                    0,
                  )
                : 0
            }
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {messageList ? (
                    <>
                      {isEmpty(messageList) && (
                        <ListItem alignItems="flex-start" className={classes.listItem}>
                          <div className={classes.noNotifications}>
                            Pas de notifications
                          </div>
                        </ListItem>
                      )}
                      {messageList.map((notification, index) => (
                      <React.Fragment key={notification.id}>
                        <ListItem alignItems="flex-start" className={classes.listItem}>
                          <NotificationContent notification={notification} />
                        </ListItem>
                        {index < messageList.length - 1 ? (
                          <Divider className={classes.divider} />
                        ) : null}
                      </React.Fragment>
                    ))}
                    </>
                  ) : (
                    <div className={classes.loading}>
                      <CircularProgress size={32} />
                    </div>
                  )}
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
}