function checkNotificationPromise() {
	try {
		Notification.requestPermission().then();
	} catch(e) {
		return false;
	}

	return true;
}

export function askNotificationPermission(onSuccess, onFailure) {
  // function to actually ask the permissions
  function handlePermission(permission) {
    // set the button to shown or hidden, depending on what the user answers
    if (Notification.permission === 'denied' || Notification.permission === 'default') {
			onFailure()
		} else {
			onSuccess()
    }
  }

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log("This browser does not support notifications.");
  } else {
    if(checkNotificationPromise()) {
      Notification.requestPermission()
      .then((permission) => {
        handlePermission(permission);
      })
    } else {
      Notification.requestPermission(function(permission) {
        handlePermission(permission);
      });
    }
  }
}

export function displayNotification({ title, icon, body, tag, onShow, onClick, onClose, onError }) {
	const notification = new Notification(title, { 
		body, 
		icon, 
		tag,
	})

	// These events can be tracked using the onclick, onclose, onerror, and onshow handlers. 
	// Because Notification also inherits from EventTarget, it's possible to use the 
	// addEventListener() method on it.

	// Triggered when the user clicks on the notification.
	notification.onclick = onClick
	// Triggered once the notification is closed.
	notification.onclose= onClose
	// Triggered if something goes wrong with the notification; this is usually because the 
	// notification couldn't be displayed for some reason.
	notification.onerror= onError 
	notification.onshow = onShow
}