import { createApiObjectReducer } from 'reacticoon/reducer'
import { runCommand } from './actions'
import { RequestStatus } from 'reacticoon/api/constants'

// override success to handle paginateCommand
const handleSuccess = (state, action) => {
  state = state.merge({
    isPending: false,
    error: null,
    status: RequestStatus.LOADED,
  })

  if (!action.payload.paginateCommand) {
    state = state.merge({
      data: action.response,
    })
  } else {
    const current = state.getIn(['data', 'data']) || []
    // reset if page is 0
    if (action.response.paging?.page === 0) {
      action.response.data = [...action.response.data]
    } else {
      action.response.data = [...current, ...action.response.data]
    }
    state = state.merge({
      data:  action.response
    })
  }

  return state
}

const CommandModuleReducer = createApiObjectReducer(runCommand, (action) => {
  return [action.payload.command, action.payload.id]
},
{
  overrideReducers: {
    [runCommand.SUCCESS]: handleSuccess,
  }
})

export default CommandModuleReducer
