const getTabsConfigManagement = () => ({
  tabs: [
    {
      label: 'Dashboard',
      route: 'SERVER_DASHBOARD',
    },
    {
      label: 'Statistiques',
      route: 'STATS',
    },
    {
      label: 'Remboursements',
      route: 'REFUNDS',
    },
    {
      label: 'Factions',
      route: 'FACTION',
    },
    {
      label: 'Compatibilité des factions',
      route: 'FACTION_COMPATIBILITY',
    },
    {
      label: 'Véhicules',
      route: 'VEHICLES',
    },
    {
      label: 'Ressources',
      route: 'GAME_RESOURCES',
    }
  ],
})

export default getTabsConfigManagement
