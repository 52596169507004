import blue from '@material-ui/core/colors/blue'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

//groups

export const Group = {
  USER: 'user',
  SUPPORT: 'support',
  MOD: 'mod',
  SUPERMOD: 'supermod',
  STAFF: 'staff',
  ADMIN: 'admin'
}

export const getGroupLabel = group => {
  const labels = {
    [Group.USER]: 'Pas dans le staff',
    [Group.SUPPORT]: 'Support',
    [Group.MOD]: 'Modérateur',
    [Group.SUPERMOD]: 'Super Modérateur',
    [Group.STAFF]: 'Administrateur',
    [Group.ADMIN]: 'Fondateur'
  }
  return labels[group] || group
}

const groupColors = {
  admin: '#ca111a',
  staff: '#b33554',
  supermod: '#9b59b6',
  mod: '#206695',
  support: '#03c500',
}

export const getGroupColor = group => {
  return groupColors[group]
}

//localGroups

export const LocalGroup = {
  DOSSIERS: 'dossiers',
  MORTRP: 'mortrp',
  DEVELOPER: 'developer',
  SIEM: 'siem',
  EVENT: 'event'
}

export const getLocalGroupLabel = localGroup => {
  const labels = {
    [LocalGroup.DOSSIERS]: 'Dossiers',
    [LocalGroup.MORTRP]: 'Mort RP',
    [LocalGroup.DEVELOPER]: 'Développeur',
    [LocalGroup.SIEM]: 'SIEM',
    [LocalGroup.EVENT]: 'Event'
  }
  return labels[localGroup] || localGroup
}

const localGroupColors = {
  dossiers: null,
  mortrp: null,
  developer: null,
  siem: null,
  event: null
}

export const getLocalGroupColor = localGroup => {
  return localGroupColors[localGroup]
}

export const getLocalGroupOptions = () => {
  return Object.values(LocalGroup)
    .filter(localGroup => localGroup !== LocalGroup.DEVELOPER) // do not allow developer
    .map(localGroup => {
      return {
        label: getLocalGroupLabel(localGroup),
        value: localGroup,
      }
    })
}