import React from "react"

function DelayedComponent({ children, delay = 2000 }) {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)

    return () => clearTimeout(timeout)

  }, [show, delay])

  if (!show) return null

  return children
}

export default DelayedComponent