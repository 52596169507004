import React from 'react'

import isEmpty from 'lodash/isEmpty'

import SecurityManager from 'modules/security/SecurityManager'
import useCadFactionChooser from 'components/useCadFactionChooser'
import ListChooser from 'components/ListChooser'

function FactionChooser({ ...props }) {
  return (
    <ListChooser
      style={{
        maxWidth: 320
      }}
      placeholder="Choisir une faction"
      fullWidth
      {...props}
    />
  )
}


export function LeftView() {
  const { setSelectedCadFaction, loggedInFaction, options, } = useCadFactionChooser()

  return <FactionChooser
    disabled={isEmpty(options) || (options.length === 1 && loggedInFaction === options[0].value)}
    value={loggedInFaction || ''}
    options={options}
    onChange={(e) => {
      setSelectedCadFaction(e.target.value)
      window.location.reload()
    }}
  />
}


// some params are unused but enforce the useContext to retrieve them, making the tabs reloaded
// on data changes
const createTabsConfigCad = (loggedInFaction, jobsWithService, settings, userMe) => () => {
  if (!settings || !jobsWithService) {
    return { tabs: [] }
  }

  return {
    leftView: () => <LeftView />,
    tabs: [
      (SecurityManager.hasCadPermission(loggedInFaction, `dispatch`) || SecurityManager.hasPermission('api.cad')) && jobsWithService.includes(loggedInFaction) && 
      {
        label: 'Dispatch',
        route: 'CAD_DASHBOARD',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `playerCanSeeDashboard`)
        || SecurityManager.hasCadPermission(loggedInFaction, `playerCanSearchByName`) 
        || SecurityManager.hasCadPermission(loggedInFaction, `playerCanSearchByPhoneNumber`) 
        || SecurityManager.hasCadPermission(loggedInFaction, `plate`) 
        || SecurityManager.hasPermission('api.cad')
        ) && {
        label: 'Rechercher',
        route: 'CAD_SEARCH',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `weaponSellCanViewSoldWeapons`) || SecurityManager.hasPermission('api.cad')) && {
        label: 'Armes',
        route: 'CAD_WEAPONS',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `playerCanSeeWantedPlayers`) || SecurityManager.hasPermission('api.cad')) && {
        label: 'Personnes recherchées',
        route: 'CAD_WANTED_PLAYERS',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `plate`) || SecurityManager.hasPermission('api.cad')) && {
        label: 'Véhicules recherchés',
        route: 'CAD_WANTED_VEHICLES',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `factionVehicle`) || SecurityManager.hasPermission('api.cad')) && {
        label: "Véhicules de l'entreprise",
        route: 'CAD_FACTION_VEHICLES',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `factionPet`) || SecurityManager.hasPermission('api.cad')) && {
        label: "Animaux de l'entreprise",
        route: 'CAD_FACTION_PETS',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `workforce`) || SecurityManager.hasPermission('api.cad')) && {
        label: 'Effectifs',
        route: 'CAD_WORKFORCE',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `help`) || SecurityManager.hasPermission('api.cad')) && {
        label: 'Aide',
        route: 'CAD_HELP',
      },
      (SecurityManager.hasCadPermission(loggedInFaction, `permission`) || SecurityManager.hasPermission('api.cad')) && 
      {
        label: 'Permissions',
        route: 'CAD_PERMISSIONS'
      }
    ].filter(Boolean),
  }

}

export default createTabsConfigCad