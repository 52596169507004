import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import useRouting from "reacticoon/routing/useRouting"
import LinesEllipsis from 'react-lines-ellipsis'

const useTicketWatcherFlashMessageNotificationStyles = makeStyles(theme => ({
	root: {
		cursor: 'pointer',
	},
	// content: {
  //   fontSize: '13px',
  //   fontFamily: 'monospace',
  //   paddingLeft: theme.spacing(1),
  // },
}))

function TicketWatcherFlashMessageNotification({ lastFeed }) {
	const classes = useTicketWatcherFlashMessageNotificationStyles()
	const { redirectTo } = useRouting()

	return (
		<div
			onClick={() => {
				redirectTo('TICKET', { displayId: lastFeed.ticketDisplayId })
			}}
			className={classes.root}
		>
			<div>#{lastFeed.ticketDisplayId} - <span>{lastFeed.authorDisplayName}</span></div>
			<div>
				<LinesEllipsis className={classes.content} text={lastFeed.resume} maxLine="2" />
			</div>
		</div>
	)
}

export default TicketWatcherFlashMessageNotification