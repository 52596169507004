import { createSimpleContainer } from 'reacticoon/container'

import UserMeModule from 'modules/userMe'

const UserMeContainer = createSimpleContainer('App::UserMeModule::UserMeContainer', {
  module: UserMeModule,
  apiCallAction: 'fetchUserMe',
  forceReload: false,
})

export default UserMeContainer
