import { findOnArray } from 'reacticoon/utils/array'

export function getJobData(jobs = [], jobType, jobGrade) {
  const job = findOnArray(jobs, job => job.name === jobType)
  if (!job) {
    return null
  }
  const grade = findOnArray(job.grades, (gradeData) => gradeData.grade === Number(jobGrade))
  return {
    ...grade,
    job,
  }
}
