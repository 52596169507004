import React from "react"
import Box from "@material-ui/core/Box"
import MessageBlock from './MessageBlock'
import DelayedComponent from "./DelayedComponent"
import CircularProgress from '@material-ui/core/CircularProgress';
import ReadyState from 'react-use-websocket';

function DefaultView({ className }) {
	return <Box  className={className} p={2}><MessageBlock.Info text="Connexion au temps réel..." /></Box>
}

function LoadingView({ className, size }) {
	return <CircularProgress size={size} className={className} />
}

function WebsocketStatusView({
	loggedIn,
	subscribed,
	readyState,
	variant = "default",
	className,
	size = 20,
}) {
	const isReadyStateConnecting = readyState !== ReadyState.OPEN

	if ((!loggedIn && isReadyStateConnecting) || !subscribed) {
		return (
			<DelayedComponent delay={1500}>
				{variant === "default" && (<DefaultView className={className} />)}
				{variant === "loading" && (<LoadingView className={className} size={size} />)}
			</DelayedComponent>
		)
	}

	return null
}

export default WebsocketStatusView