import { createModule } from 'reacticoon/module'

import * as actions from './actions'
import { fetchPublicVariablesListSelectors } from './selectors'
import reducer from './reducer'

const PublicVariables = createModule('App::PublicVariables', {
  actions,
  reducer,
  selectors: fetchPublicVariablesListSelectors,
})

export default PublicVariables
