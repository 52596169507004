import isEmpty from 'lodash/isEmpty'

export const formatPrice = (amount, currency = 'USD') => {
  if (isEmpty(currency)) { // for example for chips
    return (amount || 0).toFixed(0)
  }
  if (Intl && Intl.NumberFormat) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    })
    return formatter.format(amount || 0).replace(".00", "")
  }
  return (amount || 0).toFixed(0)
}
