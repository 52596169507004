import { getQueryParam } from 'reacticoon/routing'
import { Application } from 'reacticoon/archi'

//
// options
//

import modules from './config/modules'
import i18n from './config/i18n'
import routing from './config/routing'
import Content from './config/Content'
import ApiManagerOptions from './config/ApiManagerOptions'
import plugins from './config/plugins'

//
// custom app configuration
//

// Roboto font
import 'typeface-roboto'
import './reset.css'

//
// Application configuration
//

if (getQueryParam('isGame')) {
  // remove logs from the game console (f8)
  console.log = () => {}
  console.warn = () => {}
  console.debug = () => {}
  console.info = () => {}
}

const appOptions = {
  modules,
  i18n,
  plugins,
  routing,
  Content,
  ApiManagerOptions,
}

Application(appOptions)
