import { createFormatter, createSubObjectFormatter } from 'reacticoon/format'
// import { formatPlayers } from 'modules/players/format'
import { getJobData } from 'modules/jobGrades/constants'

const formatUserPlayer = createFormatter((player, { publicVariables }) => {
  if (!publicVariables) {
    return player
  }
  player.jobData = getJobData(publicVariables.jobs, player.job, player.jobGrade)
  player.jobLabel = player.jobData?.job?.label || player.job || 'Aucun'
  player.jobGradeLabel = player.jobData?.label || player.jobGrade || 'Aucun'

  player.job2Data = getJobData(publicVariables.jobs, player.job2, player.job2Grade)
  player.job2Label = player.job2Data?.job?.label || player.job2 || 'Aucun'
  player.job2GradeLabel = player.job2Data?.label || player.job2Grade || 'Aucun'

  player.jobNames = [player.job, player.job2].filter(Boolean)
  return player
})

export const formatUser = createFormatter((user, { publicVariables }) => {
  user.avatarUrl = `https://avatars.dicebear.com/api/initials/${user.username}.svg`
  return user
}, createSubObjectFormatter('gamePlayer', formatUserPlayer))
