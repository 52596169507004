import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(() => ({
	26: {
		height: '26px',
		minWidth: 0,

		'& .MuiSvgIcon-root': {
			height: '26px',
		},
	},
	24: {
		height: '26px',
		minWidth: 0,

		'& .MuiSvgIcon-root': {
			height: '26px',
		},
	},
	22: {
		height: '22px',
		minWidth: 0,

		'& .MuiSvgIcon-root': {
			height: '22px',
		},
	},
	20: {
		height: '20px',
		minWidth: 0,

		'& .MuiSvgIcon-root': {
			height: '20px',
		},
	},
	18: {
		height: '18px',
		minWidth: 0,

		'& .MuiSvgIcon-root': {
			height: '18px',
		},
	},
}))

const sizes = [
	'26',
	'24',
	'22',
	'20',
	'18',
]

function TaseButton({ size = "medium", className, children, ...otherProps }) {
	const classes = useStyles()

	if (!sizes.includes(size)) {
		return <Button className={className} size={size} {...otherProps}>{children}</Button>
	}

	const sizeClassName = classes[size]
	if (!sizeClassName) {
		throw new Error(`Invalid size for TaseButton ${size}`)
	}

	return (
		<Button
			className={clsx(sizeClassName, className)}
			{...otherProps}
		>
			{children}
		</Button>
	)
}

export default TaseButton