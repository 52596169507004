import { createApiCallAction } from 'reacticoon/action'

export const postRegister = createApiCallAction(
  'App::RegisterModule::Register',
  ({ email, username, password, password2, recaptchaResponse }) => ({
    type: 'POST',
    endpoint: '/commands/AUTH_REGISTER',
    body: {
      command: 'AUTH_REGISTER',
      payload: {
        username,
        email,
        password,
        password2,
        recaptchaResponse,
      },
    },
  })
)
