import { createMiddleware } from 'reacticoon/middleware'

import { fetchUserMe } from './actions'
import  SecurityManager from 'modules/security/SecurityManager'

export const fetchUserMeMiddleware = createMiddleware(
  'App::UserMeModule::fetchUserMe',
  fetchUserMe.SUCCESS, 
  ({ action, dispatch }) => {
    SecurityManager.registerUser(action.response || {})
  }
)
