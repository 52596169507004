import React, { useContext, useState, useCallback, createContext, useEffect, useMemo, useDebugValue } from "react";
import { __DEV__ } from 'reacticoon/environment'
import last from 'lodash/last'
import isString from 'lodash/isString'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getEnvVar } from 'reacticoon/environment'
import { getJwtToken } from 'modules/auth/config'

const cache = {}

function cacheMessages(subscriptionType, messages, getId) {
	const current = cache[subscriptionType] || []

	if (!current) {
		cache[subscriptionType] = messages
	} else {
		const currentIds = cache[subscriptionType].map(m => getId(m))
		const newIds = messages.map(m => getId(m))
		const intersection = newIds.filter(x => currentIds.includes(x))

		if (currentIds.length !== newIds.length || intersection.length > 0) {
			console.log(`intersection: ${subscriptionType}`, currentIds, newIds)
			cache[subscriptionType] = messages
		}
	}


	return cache[subscriptionType]
}

function updateMessageHistory(prev, lastMessage) {
	let result = [...prev]

	const doNotKeepHistory = [
		'SUBSCRIBE',
		'UNSUBSCRIBE',
		'LOGIN',
	]

	if (doNotKeepHistory.includes(lastMessage.type)) {
		// do not concat, do not keep in history
		return result
	}

	// TODO: clean older messages, REPORTS: keep last one
	if (lastMessage.type === 'BROADCAST') {

		if (
			lastMessage.channel.startsWith("TICKET/") // TICKET/42
			|| lastMessage.channel.startsWith("TICKET_TYPING/") // TICKET_TYPING/42
			|| lastMessage.channel.startsWith("PLAYER_COUNT")
		) { 
			result = result.filter(r => r.channel !== lastMessage.channel)
		} else {
			switch (lastMessage.channel) {
				// keep last one:
				case 'REPORTS':
				case 'TICKET':
					result = result.filter(r => r.channel !== lastMessage.channel)
					break

				default:
			}
		}
	}

	result = result.concat(lastMessage)

	if (__DEV__) {
		console.log({ messageHistory: result })
	}

	return result
}

let ContextWebsocket;
let { Provider } = (ContextWebsocket = createContext());

ContextWebsocket.displayName = 'ContextWebsocket'

export const WebsocketProvider = ({ children }) => {
	const [messageHistory, setMessageHistory] = useState([]);
	const [subscriptions, setSubscriptions] = useState({})
	const [loggedIn, setLoggedIn] = useState(false)

	const { sendJsonMessage: _sendMessage, lastMessage: lastMessageData, readyState } = useWebSocket(
		getEnvVar('WEBSOCKET_URL'),
		{
			onOpen: () => {
				// console.log('websocket opened')
			},
			onClose: () => {
				// console.log('websocket closed')
				setSubscriptions([])
				setLoggedIn(false)
			},
			//Will attempt to reconnect on all close events, such as server shutting down
			shouldReconnect: (closeEvent) => true,
		}
	);

	function onReceive(message) {
		if (message.type === 'LOGIN') {
			// TODO: if response ok
			setLoggedIn(true)
		}
		
		// trick just in case we missed a SUBSCRIBE
		if (message.type === "BROADCAST" && !subscriptions[message.channel]) {
			setSubscriptions({ ...subscriptions, [message.channel]: { connected: true } })
		}
		if (message.type === "UNSUBSCRIBE") {
			setSubscriptions({ ...subscriptions, [message.channel]: { connected: false } })
			cache[message.channel] = []
		}
		if (message.type === "SUBSCRIBE") {
			setSubscriptions({ ...subscriptions, [message.channel]: { connected: true } })
			cache[message.channel] = []
		}
	}

	const sendMessage = useCallback((command, payload) => {
		_sendMessage(
			{
				command,
				payload
			}
		)
	}, [_sendMessage])

	const login = useCallback(() => sendMessage('LOGIN', {
		jwt: getJwtToken()
	}), [sendMessage]);

	const subscribe = useCallback((type) => {
		// console.info(`SUBSCRIBE ${type}`)
		// if (!subscriptions[type]) {
			sendMessage('SUBSCRIBE', {
				type,
			})
		// }
	}, [sendMessage]);

	const unsubscribe = useCallback((type) => {
		// console.info(`UNSUBSCRIBE ${type}`)
		// if (subscriptions[type] === true) {
			sendMessage('UNSUBSCRIBE', {
				type,
			})
		// }
	}, [sendMessage ]);

	useEffect(() => {
		if (lastMessageData !== null) {
			if (isString(lastMessageData.data)) { // else is a binary, API didn't correctly sent a message, ignore it
				const lastMessage = JSON.parse(lastMessageData.data) 

				setMessageHistory(prev => updateMessageHistory(
					prev,
					lastMessage,
				));

				onReceive(lastMessage)
			}
		}
	}, [lastMessageData, setMessageHistory]);

	useEffect(() => {
		if (readyState === ReadyState.OPEN) {
			login()
		}
	}, [login, readyState])

	const connectionStatus = {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	}[readyState];

	const value = {
		loggedIn,
		connectionStatus,

		messageHistory,
		// lastMessage: last(messageHistory),
		sendMessage,
		readyState,
		login,
		subscribe,
		unsubscribe,
		subscriptions,
	}

	return (
		<Provider value={value}>
			{children}
		</Provider>
	);
};

export default function useWebsocket() {
	const context = useContext(ContextWebsocket)
	if (context === undefined) {
		throw new Error('useWebsocket must be used within a WebsocketProvider')
	}

	// console.info(context?.character)
	return context
}

export function useWebsocketChannel(subscriptionType, options = {}) {
	const { 
		loggedIn, 
		readyState, 
		sendMessage: _sendMessage, 
		subscribe, 
		subscriptions, 
		unsubscribe, 
		messageHistory,
		// TODO: to be tested
		withCache = false,
		getId = null,
	} = useWebsocket()

	useDebugValue("useWebsocketChannel: " + subscriptionType)

	const sendMessage = useCallback((payload) => {
		_sendMessage(subscriptionType, payload)
	}, [_sendMessage])

	useEffect(() => {
		if (loggedIn) {
			subscribe(subscriptionType)
		}

		return () => {
			unsubscribe(subscriptionType)
		}
	}, [loggedIn, subscribe, unsubscribe, subscriptionType])

	// TODO: this is recomputed each time we receive a new websocket message
	const messages = useMemo(() => {
		const messages = messageHistory.filter(m => m.type === 'BROADCAST' && m.channel === subscriptionType)
		if (withCache) {
			return cacheMessages(subscriptionType, messages, getId)
		}
		return messages
	}, [messageHistory, subscriptionType, withCache])

	const lastMessage = useMemo(() => last(messages), [ messages ])

	useEffect(() => {
		if (lastMessage && options?.onMessage) {
			options.onMessage(lastMessage)
		}
	}, [lastMessage])

	return {
		loggedIn,
		readyState,
		sendMessage,
		subscribed: !!subscriptions[subscriptionType],
		lastMessage,
		messages,
	}
}