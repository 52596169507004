import { createModule } from 'reacticoon/module'

import * as actions from './actions'
import { fetchSearchSelectors } from './selectors'
import reducer from './reducer'

const SearchModule = createModule('App::SearchModule', {
  actions,
  reducer,
  selectors: fetchSearchSelectors,
})

export default SearchModule
