import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PoliceBadge = ({ width = 32, height = 32, ...props }) => (
  <SvgIcon viewBox="0 0 344.000000 344.000000" preserveAspectRatio="xMidYMid meet" {...props}>
    <g
      transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)"
      fill="inherit"
      stroke="none"
    >
      <path
        d="M1660 3253 c-8 -3 -58 -31 -110 -63 -182 -109 -237 -130 -356 -130
-104 0 -182 28 -308 110 -178 115 -206 112 -360 -38 -112 -110 -136 -147 -136
-205 0 -19 31 -117 70 -219 91 -240 104 -305 97 -473 -6 -154 -20 -210 -107
-415 -98 -233 -123 -364 -108 -575 21 -300 193 -592 449 -758 46 -30 255 -135
464 -233 l380 -178 85 0 85 0 380 178 c209 98 417 203 463 232 203 132 351
339 424 590 20 69 23 101 23 259 0 216 -7 246 -106 487 -88 212 -109 299 -109
445 0 147 16 218 100 440 70 186 79 228 59 280 -12 32 -201 228 -241 249 -15
8 -49 14 -75 14 -42 0 -65 -10 -173 -73 -165 -97 -197 -108 -306 -115 -128 -7
-195 15 -348 114 -103 68 -125 78 -170 81 -28 1 -58 0 -66 -4z m183 -184 c132
-86 218 -118 336 -126 148 -9 240 18 416 122 61 35 119 65 130 65 24 0 205
-177 205 -202 0 -9 -21 -71 -47 -138 -32 -84 -49 -118 -57 -110 -15 15 -52 12
-70 -6 -12 -11 -16 -36 -16 -99 0 -71 3 -86 19 -97 17 -12 18 -19 9 -73 -15
-90 -4 -286 20 -381 12 -44 51 -152 87 -240 88 -213 109 -297 108 -449 0 -132
-14 -205 -63 -333 -61 -157 -191 -318 -336 -415 -40 -26 -240 -126 -447 -222
-295 -138 -384 -175 -416 -175 -33 0 -122 38 -419 176 -207 97 -408 197 -447
222 -158 104 -288 275 -351 459 -83 246 -68 425 61 738 36 88 75 196 86 240
24 95 35 296 21 380 -9 54 -8 61 9 73 16 11 19 26 19 97 0 63 -4 88 -16 99
-18 18 -55 21 -70 6 -8 -8 -25 26 -57 110 -26 67 -47 129 -47 138 0 21 179
202 201 202 9 0 63 -31 121 -68 124 -80 210 -111 329 -119 149 -10 266 28 454
145 44 28 89 51 99 51 11 1 69 -31 129 -70z"
      />
      <path
        d="M878 2679 c-15 -8 -18 -25 -18 -98 0 -96 8 -113 55 -113 46 0 55 17
55 107 0 67 -3 85 -18 98 -21 19 -49 21 -74 6z"
      />
      <path
        d="M1140 2670 c-16 -16 -20 -33 -20 -90 0 -62 3 -74 25 -96 19 -19 31
-23 48 -18 42 13 47 23 47 109 0 67 -3 85 -18 98 -25 23 -58 21 -82 -3z"
      />
      <path
        d="M1410 2670 c-16 -16 -20 -33 -20 -92 0 -79 4 -89 37 -107 26 -13 67
-1 77 24 3 9 6 49 6 89 0 58 -4 76 -18 89 -25 23 -58 21 -82 -3z"
      />
      <path
        d="M1676 2668 c-21 -29 -22 -156 -2 -183 8 -11 26 -18 46 -18 47 0 60
23 60 108 0 84 -16 115 -60 115 -18 0 -34 -8 -44 -22z"
      />
      <path
        d="M1947 2672 c-13 -14 -17 -38 -17 -89 0 -39 3 -79 6 -88 10 -25 51
-37 77 -24 33 18 37 28 37 107 0 59 -4 76 -20 92 -25 25 -62 26 -83 2z"
      />
      <path
        d="M2217 2672 c-21 -23 -24 -174 -4 -190 30 -24 56 -23 82 2 22 22 25
34 25 96 0 57 -4 74 -20 90 -25 25 -62 26 -83 2z"
      />
      <path
        d="M2487 2672 c-13 -14 -17 -38 -17 -99 0 -88 9 -105 55 -105 47 0 55
17 55 113 0 77 -2 90 -19 99 -29 15 -56 12 -74 -8z"
      />
      <path
        d="M1677 2122 c-9 -10 -48 -97 -88 -193 -39 -95 -74 -177 -77 -180 -4
-4 -92 -14 -197 -23 -104 -9 -200 -19 -212 -21 -28 -6 -47 -40 -39 -70 5 -18
225 -222 304 -281 12 -9 8 -39 -32 -203 -25 -106 -46 -200 -46 -210 0 -23 31
-51 57 -51 11 0 98 49 194 108 l175 108 174 -108 c96 -59 184 -108 196 -108
26 0 54 30 54 57 0 17 -68 314 -86 375 -3 11 42 57 156 158 169 149 182 168
141 209 -15 15 -46 21 -167 31 -82 7 -175 15 -208 19 l-58 6 -75 185 c-41 102
-82 191 -91 198 -23 17 -56 15 -75 -6z m93 -331 c28 -71 59 -136 68 -145 13
-11 59 -19 157 -27 77 -6 146 -15 152 -18 8 -4 -28 -42 -98 -101 -61 -52 -115
-104 -119 -116 -5 -13 4 -70 25 -159 19 -77 33 -141 32 -142 -1 -1 -57 32
-125 74 -68 42 -134 76 -147 76 -13 0 -74 -32 -137 -70 -62 -39 -118 -73 -125
-77 -13 -7 -14 -16 27 152 17 68 28 132 25 142 -3 10 -58 64 -121 119 -132
116 -138 104 60 121 72 6 137 15 146 20 9 5 40 70 70 144 29 75 54 136 56 136
1 0 25 -58 54 -129z"
      />
    </g>
  </SvgIcon>
)

export default PoliceBadge