import { createApiCallAction } from 'reacticoon/action'

export const fetchUserMe = createApiCallAction('App::UserMeModule::fetchUserMe', (payload) => ({
  type: 'POST',
  endpoint: '/commands/AUTH_GET_ME',
  body: {
    command: 'AUTH_GET_ME',
    payload,
  },
}))
