import { formatPlayer } from "modules/player/format"
import moment from "moment"
import isEmpty from "lodash/isEmpty"
import last from "lodash/last"
import SecurityManager from 'modules/security/SecurityManager'
import formatHtmlContent from "../../modules/html/formatHtmlContent";
import convertDiscordMarkdownToHTML from "../../modules/html/formatDiscordContent";
import blue from '@material-ui/core/colors/blue'
import lightBlue from '@material-ui/core/colors/lightBlue'
import orange from '@material-ui/core/colors/orange'
import { getGroupColor } from "modules/player/constants"
import { encodeTinyId } from "modules/tinyid"

function getColor(ticket) {
	if (ticket.waitingResponse) {
		return orange[300]
	}

	if (ticket.fromStaff) {
		return blue[200]
	}

	return 'white'
}

function getMessageAuthorColor(message) {
	if (message.fromBot) {
		return "rgb(32, 102, 148)"
	}

	if (message.fromCreator) {
		return null
	}

	if (message.fromStaff) {
		// TODO: by group, we do not have the data
		if (message.authorPlayer?.group) {
			return getGroupColor(message.authorPlayer?.group)
		}
		return lightBlue[700]
	}
}

function getStateColor(state) {
	switch (state) {
		case "CREATED":
			return blue[600]
		case "RUNNING":
			return blue[600]
		case "CLOSED":
			return ""

		default:
			return ""
	}
}

function getStateLabel(state) {
	switch (state) {
		case "CREATED":
			return "Crée"
		case "RUNNING":
			return "En cours"
		case "CLOSED":
			return "Fermé"

		default:
			return state
	}
}

function formatDate(date) {
	const today = moment()
	const yesterday = moment().add(-1, 'days');

	if (today.isSame(date, "day")) {
		return `Aujourd'hui à ${date.format('HH:mm')}`
	} else if (yesterday.isSame(date, "day")) {
		return `Hier à ${date.format('HH:mm')}`
	} else {
		return date.format('DD/MM/YYYY')
	}
}

const urlRegex = /(https?:\/\/[^ ]*)/

function cleanMessageUrl(domain, content) {
	// TODO: handle domain and not any url -> here we assune there is only one url of the given domain
	// in the content
	const url = content.match(urlRegex)[1]
	return content.replaceAll(url, "").trim()
}

// props ticket / mePlayer are optionnal (not given from NotificationWebSocket)
export function formatFeedMessage(message, { ticket, mePlayer } = {}) {
	// without it we format images multiples times
	if (message.isFormatted) {
		return message
	}

	message.ticketDisplayId = encodeTinyId(message.ticketId || ticket?.id)

	message.isMessageOpeningTicketDiscussionWithBotFirstMessage = message.content.includes("Avant qu'un membre du staff puisse répondre à ta demande, nous t'invitons à choisir le type de demande que tu formules :")
	|| message.content.includes("Avant de commencer, j'ai trouvé plusieurs personnages reliés à ton compte Discord.")

	// last message for DEBAN, TODO: other types
	message.isMessageOpeningTicketDiscussionWithBotLastMessage = message.content.includes("Avant qu'un membre de l'équipe ne réponde à ta demande, merci de préciser au maximum les détails de l'erreur que tu as constatée dans cette sanction.") //deban
	|| message.content.includes("Merci de préciser au maximum les détails de ta demande. Ce ticket n'a pas pour but de négocier un bannissement ou de demander un déban anticipé.") //deban
	|| message.content.includes("avant qu'une réponse ne te soit apportée, merci de préciser au maximum les détails de ta demande, et d'envoyer les vidéos en cas de constatation de bug ou de non respect du règlement.") //ticket
	|| message.content.includes("Il est obligatoire de fournir un dossier de mort RP en suivant le modèle suivant") //mortrp
	|| message.content.includes("Merci de nous envoyer un Google Doc contenant ta candidature") //candidature
	|| message.content.includes("il s'agit d'une demande de réinitialisation de ton personnage") //register

	message.isMessageOpeningTicketDiscussionWithBot = message.isMessageOpeningTicketDiscussionWithBotFirstMessage
		|| message.isMessageOpeningTicketDiscussionWithBotLastMessage

	message.fromBot = message.fromType === "BOT"
	message.fromStaff = message.fromType === "STAFF"
	message.fromCreator = message.fromType === "CREATOR"

	message.createdAt = moment(message.createdAt)
	message.createdAtFormatted = formatDate(message.createdAt)

	// default values
	message.textHasRawContent = false
	message.resume = "[inconnu]"
	message.textHasVideo = false
	message.textHasImage = false
	message.textHasLink = false
	message.textHasYoutubeVideo = false
	message.textHasRawContent = false

	const trimmedContent = message.content?.trim()

	if (message.content.startsWith("Nous te remercions d'avoir contacté le support Baylife. A bientôt !")) {
		message.content = "> Fermeture du ticket"
		message.resume = "> Fermeture du ticket"
	} else if (message.content === "Un ou plusieurs membres du staff sont maintenant assignés à ta demande. Merci de patienter, une réponse te sera apportée très bientôt.") {
		message.content = "> Assignation de ticket"
		message.resume = "> Assignation de ticket"
	} else if (trimmedContent.startsWith("https://tenor.com/view/")) {
		// text = ``
		message.isTenorGif = true

		const match = message.content.match(/(https?:\/\/tenor.com.*-(\d.*))/)

		message.tenorGifEmbedurl = 'https://tenor.com/embed/'
		if (match?.length >= 3) {
			message.directUrl = match[1]
			message.tenorGifId = match[2]
		} else {
			message.tenorGifId = null
		}

		message.resume = "[gif]"
		message.content = cleanMessageUrl("https://tenor.com/view/", trimmedContent)

	} else if (trimmedContent.includes("https://clips.twitch.tv")) {
		message.isTwitchClip = true

		// TODO: regex
		message.directUrl = trimmedContent
		message.clipSlug = trimmedContent.replaceAll("https://clips.twitch.tv/", "")

		message.content = cleanMessageUrl("https://clips.twitch.tv", trimmedContent)

		message.resume = "[clip Twitch]"
	} else if (trimmedContent.includes("https://streamable.com/")) {
		message.isStreamable = true

		const match = trimmedContent.match(/(https:\/\/streamable.com\/(\w*))/)
		if (match && match.length >= 3) {
			message.directUrl = match[1]
			message.clipSlug = match[2]

			message.content = cleanMessageUrl("https://streamable.com/", trimmedContent)

			message.resume = "[clip Streamable]"
		}
	}
	else if (message.content.includes("https://docs.google.com/document/")) {
		message.hasGoogleDocument = true
		message.googleDocuments = []
		const googleDocuments = message.content.matchAll(/(https:\/\/docs\.google\.com\/document\/d\/([a-zA-Z0-9-_]*)\/([\w?=&-]*))/g);
		Array.from(googleDocuments).map(match => {
			message.content = message.content.replace(`${match[0]}\n\n`, "")
			message.content = message.content.replace(`${match[0]}\n`, "")
			message.content = message.content.replace(`${match[0]}`, "")
			message.googleDocuments.push({
				googleDocumentUrl: match[0],
				googleDocumentId: match[2],
				googleDocPreviewUrl: `https://lh3.google.com/u/0/d/${match[2]}=w200-h150-p-k-nu-iv1`
			})
		})
		
		message.resume = "[Google document]"
	} else if (/<@.*>$/.test(message.content)) { // <@735253273208160358> -> Discord TAG
		message.isDiscordTag = true
		if (message.content === "<@735253273208160358>") {
			message.content = "@SupportBaylife"
			message.resume = "[PING] @SupportBaylife"
		} else {
			message.content = "[PING]: " + message.content
			message.resume = "[PING]"
		}
	} else {
		message.textHasRawContent = true
		const htmlContent = formatHtmlContent(convertDiscordMarkdownToHTML(message.content))

		message.textHasVideo = htmlContent.textHasVideo
		message.textHasImage = htmlContent.textHasImage
		message.textHasSound = htmlContent.textHasSound
		message.textHasLink = htmlContent.textHasLink
		message.textHasYoutubeVideo = htmlContent.textHasYoutubeVideo
		message.textHasRawContent = htmlContent.textHasRawContent

		message.content = htmlContent.content

		if (!htmlContent.textHasRawContent) {
			if (htmlContent.textHasVideo) {
				message.resume = "[video]"
			} else if (htmlContent.textHasImage) {
				message.resume = "[image]"
			} else if (htmlContent.textHasSound) {
				message.resume = "[audio]"
			} else if (htmlContent.textHasLink) {
				message.resume = "[link]"	
			} else if (htmlContent.textHasYoutubeVideo) {
				message.resume = "[youtube]"
			}  else {
				message.resume = "[inconnu]"
			}
		} else {
			message.resume = htmlContent.content.substr(0, 100)
			// Remove \n added by discord
			// message.content = message.content.replaceAll("\n", " ")
		}
	}
	message.hasNoPlayer = !message.authorPlayer
	message.authorDisplayName = message.authorPlayer?.fullName || ticket?.creatorDiscordName || "Inconnu"

	if (message.fromCreator) {
		message.creatorDiscordAvatar = ticket?.creatorDiscordAvatar
	}

	// TRICK: bad data for accounts without a discord image (default discord avatar).
	if (message.creatorDiscordAvatar?.endsWith("null.png")) {
		message.creatorDiscordAvatar = null
	}


	message.meIsCreator = message.author === mePlayer?.identifier

	message.canEdit = message.fromStaff && (message.meIsCreator || SecurityManager.hasPermission("api.ticket.canUpdateAllMessages"))
	message.canDelete = message.canEdit

	message.hasEdit = !isEmpty(message.previousVersions)

	if (message.hasEdit) {
		message.previousVersions.forEach(previousVersion => {
			previousVersion.editedAt = moment(previousVersion.editedTimestamp)
		})
	}

	message.authorColor = getMessageAuthorColor(message)

	message.isFormatted = true
	return message
}

function formatTicket(ticket, mePlayer) {
	ticket.waitingResponse = last(ticket.feed)?.fromCreator
	// ticket.fromStaff = ticket.creator TODO:
	ticket.color = getColor(ticket)
	ticket.createdAt = moment(ticket.createdAt)

	ticket.feed = ticket.feed?.map(message => formatFeedMessage(message, { ticket, mePlayer })) || []

	// base 26 convertion
	ticket.displayId = encodeTinyId(ticket.id)

	let isMessageOpeningTicketDiscussionWithBotFirstMessageFound = false

	const firstMessage = ticket.feed[0]
	ticket.feed.forEach((message, index) => {
		const previousMessage = index !== 0 && ticket.feed[index - 1]
		const nextMessage = ticket.feed[index + 1]

		// --
		message.isNewDate = previousMessage && !previousMessage.createdAt.isSame(message.createdAt, "day")

		if (index === 0 || message.isNewDate) {
			message.isFirstMessageOfMultiple = true
		} else {
			if (previousMessage.author !== message.author || previousMessage.fromType !== message.fromType || previousMessage.anonymous !== message.anonymous) {
				message.isFirstMessageOfMultiple = true
			} else {
				message.isFirstMessageOfMultiple = false
			}
		}

		// --
		message.isLastMessageOfMultiple = nextMessage?.author !== message.author
	
		// TRICK: sometimes we have the same message send by the bot, taken as a first message. Just keep
		// the first one.
		if (message.isMessageOpeningTicketDiscussionWithBotFirstMessage) {
			if (!isMessageOpeningTicketDiscussionWithBotFirstMessageFound) {
				isMessageOpeningTicketDiscussionWithBotFirstMessageFound = true
			} else {
				message.isMessageOpeningTicketDiscussionWithBotFirstMessage = false
			}
		}
	
		// previous is not the last message, so we mark this  one as in opening
		if (previousMessage?.isMessageOpeningTicketDiscussionWithBot 
			&& !previousMessage?.isMessageOpeningTicketDiscussionWithBotLastMessage) {
			message.isMessageOpeningTicketDiscussionWithBot = true
		}

		// TRICK: people will write their message, answer the bot questions, and then copy/paste the first
		// message. We hide it.
		// So we take the message pasted and put it on the "opening". So we modify the previous message, 
		// to not be the lastMessage of the opening anymore.
		if (previousMessage?.isMessageOpeningTicketDiscussionWithBotLastMessage 
			&& firstMessage.content === message.content 
			&& !firstMessage.deleted) {
				// previous is no more the last message
				previousMessage.isMessageOpeningTicketDiscussionWithBotLastMessage = false
				// current is the new last message
				message.isMessageOpeningTicketDiscussionWithBot = true
				message.isMessageOpeningTicketDiscussionWithBotLastMessage = true
			}
	})

	// TRICK: bad data for accounts without a discord image (default discord avatar).
	if (ticket.creatorDiscordAvatar?.endsWith("null.png")) {
		ticket.creatorDiscordAvatar = null
	}

	ticket.isTypeMortRp = ticket.type === "mortrp"
	ticket.isTypeRegister = ticket.type === "register"

	ticket.hasNoPlayer = !ticket.creator
	ticket.authorDisplayName = ticket.creator?.fullName || ticket.creatorDiscordName || "Inconnu"
	ticket.creatorDiscordNameWithDiscriminator = `${ticket.creatorDiscordName}#${ticket.creatorDiscordDiscriminator}`

	ticket.isStateClosed = ticket.state === 'CLOSED'
	ticket.player = formatPlayer(ticket.player)
	ticket.stateLabel = getStateLabel(ticket.state)
	ticket.stateColor = getStateColor(ticket.state)

	ticket.meIsAssigned = ticket.assignedMemberPlayers?.some(member => member.player.identifier === mePlayer?.identifier)
	ticket.meIsWatching = ticket.watcherPlayers?.some(member => member.player.identifier === mePlayer?.identifier)

	ticket.hasCustomName = !!ticket.customName

	ticket.ticketDisplayName = ticket.customName || ticket.authorDisplayName

	ticket.unreadMessages = ticket.unreadMessages || 0

	const lastAnswer = moment(ticket.lastAnswer)
	const date24hBefore = moment().subtract(1, 'day')
	const date72hBefore = moment().subtract(2, 'day')
	ticket.isWaitingForResponse = lastAnswer.isBefore(date24hBefore)
	ticket.isWaitingForResponseHard = lastAnswer.isBefore(date72hBefore)

	return ticket
}

export function formatTickets(tickets, mePlayer) {
  return tickets.map(t => formatTicket(t, mePlayer))
}

export default formatTicket