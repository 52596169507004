const BASE = 32

// NB: does not validate input
export function encodeTinyId(intParam) {
	let int = typeof intParam === 'string' ? parseInt(intParam, 10) : intParam
	return int.toString(BASE).toUpperCase()
}

export function decodeTinyId(str) {
	if (!str) {
		return null
	}
	if (str === "all") { // specific trick for /all
		return "all"
	}
	return parseInt(str.toLowerCase(), BASE)
}