import React, { useContext } from 'react'

import isEmpty from 'lodash/isEmpty'
import { __DEV__ } from 'reacticoon/environment'
import clsx from 'clsx'
import MenuList from '@material-ui/core/MenuList'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CadFactionContext from 'components/CadFactionContext'
import SettingsContext from 'modules/settings/context'
import PublicVariablesContext from 'modules/publicVariables/context'
import UserMeContext from 'components/UserMeContext'
import { fade } from 'reacticoon/theme/colorManipulator'
import { getRoute } from 'reacticoon/routing'
import SecurityManager from 'modules/security/SecurityManager'
import { makeStyles } from '@material-ui/core/styles'
import LogoIcon from 'components/Logo'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'reacticoon/routing'
import TypographyCustom from 'components/TypographyCustom'
import ChatIcon from '@material-ui/icons/Chat'
import DashboardIcon from '@material-ui/icons/Dashboard'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import CadIcon from 'components/PoliceBadge'
import BanlistIcon from '@material-ui/icons/Block'
import DarkChatIcon from '@material-ui/icons/FindInPage'
import SIEMIcon from '@material-ui/icons/Announcement'
import MapIcon from '@material-ui/icons/Map'
import RankingIcon from '@material-ui/icons/EmojiEvents'
import ConsoleIcon from 'components/ConsoleIcon'
import LogsIcon from 'components/LogsIcon'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import createTabsConfigCad from 'components/createTabsConfigCad'
import getTabsConfigRanking from 'components/getTabsConfigRanking'
import getTabsConfigManagement from 'components/getTabsConfigManagement'
import getTabsConfigAdministration from 'components/getTabsConfigAdministration'

const Item = ({
  route,
  currentRoute,
  subItem = false,
  subRoutesAsSubMenu = false,
  matchRoutes,
  tabs: tabsParam = [],
  children,
  classes,
  text,
  icon,
  onClose,
  isMobile,
}) => {
  const tabs = tabsParam.filter(Boolean)
  const [isOpen, setIsOpen] = React.useState(false)

  const routeConfig = getRoute(route)

  if (!SecurityManager.hasPermission(routeConfig.permissions)) {
    return null
  }

  const hasChildren = !isEmpty(tabs) || !!children

  const isCurrentRoute = currentRoute?.name === route
    || (matchRoutes && currentRoute?.path?.match(matchRoutes))
    || tabs.indexOf(currentRoute?.name) !== -1

  const item = <ListItem
    classes={{
      root: clsx(classes.menuItem, {
        [classes.currentRouteMain]: isCurrentRoute && !subItem,
        [classes.currentRouteSub]: isCurrentRoute && subItem,
        [classes.currentRouteParent]: isCurrentRoute && !subItem && hasChildren,
      }),
    }}
  >
    {icon && (
      <ListItemIcon>
        {React.cloneElement(icon, { className: classes.menuItemIcon })}
      </ListItemIcon>
    )}
    <ListItemText primary={text} classes={{ primary: classes.menuItemText }} />
  </ListItem>

  return (
    <div
      className={clsx({
        [classes.routeParent]: !subItem && hasChildren,
        [classes.subRoutesAsSubMenu]: subRoutesAsSubMenu,
      })}
      onClick={() => isMobile && setIsOpen(!isOpen)}
    >
      {(hasChildren && isMobile) ? item :
        <Link to={Link.getRoute(route)} params={routeConfig.defaultParams} onClick={onClose}>
          {item}
        </Link>
      }
      {hasChildren &&
        <div className={clsx({ 
            [classes.subMenuMobile]: isMobile, 
            [classes.open]: isOpen, 
            [classes.subMenu]: !isMobile
        })}>
          {children}
          {tabs.map(tab => (
            <Item 
              key={tab.route}
              route={tab.route}
              currentRoute={route}
              classes={classes}
              text={tab.label}
              onClose={onClose}
              isMobile={isMobile}
            />
          ))}
        </div>
      }
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  const subMenuStyle = (fromSidebarOpen) => ({
    '& $subMenu': {
      display: 'none',
    },
    [`${fromSidebarOpen ? '&' : '& $routeParent'}:hover`]: {
      '& $subMenu': {
        display: 'block',
        position: 'fixed',
        left: fromSidebarOpen ? 245 : 72,
        paddingLeft: 0,
        marginTop: -48,
        background: theme.app.background.dark,
        boxShadow: theme.shadows[6],

        '& .MuiListItem-root': {
          // paddingLeft: 0,
          minWidth: 255,
        },

        '& $menuItem': {
          paddingLeft: theme.spacing(5),
        },

        '& $menuItem:hover': {
          background: fade(theme.palette.primary.main, 0.2),
        },
      },
    },
  })

  return {
    subMenu: {
      '& $menuItem': {
        paddingLeft: theme.spacing(10),
      },
    },
    subMenuMobile: {
      display: 'none',

      '& .MuiListItem-root': {
        paddingLeft: theme.spacing(10),
      },
    },
    open: {
      display: 'block'
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      // ...theme.mixins.toolbar,
      minHeight: 48,
      marginLeft: 'auto',
    },
    leftArea: {
      boxSizing: 'border-box',
      display: 'block',
      height: theme.app.header.height,
      position: 'relative',
      color: theme.palette.common.white,
    },
    logoArea: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(1),
    },
    logo: {
      marginLeft: theme.spacing(1),
    },
    brand: {
      marginLeft: 12,
      color: theme.palette.common.white,
    },
    drawerIcon: {
      color: theme.palette.common.white,
    },
    menuList: {
      paddingTop: theme.app.header.height,
      height: `calc(100vh - ${theme.app.header.height})`,
      overflow: 'auto',
      // This property print the content from right to left so we can have the scrollbar on the left
      direction: 'rtl',

      ...theme.style.scrollbar,

      '& *': {
        // reset direction rtl
        direction: 'ltr',
      },

      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    subRoutesAsSubMenu: {
      ...subMenuStyle(true),
    },
    menuListSidebarClosed: {
      '& .MuiListItem-root': {
        paddingLeft: 22, // same as menuItem
      },

      ...subMenuStyle(false),
    },
    menuItem: {
      color: theme.palette.common.white,
      ...theme.style.focusPrimary,
      paddingLeft: 22, // same as menuListSidebarClosed

      '&:hover': {
        background: fade(theme.palette.primary.main, 0.2),
      },

      '& .MuiSvgIcon-root': {
        color: !theme.isDark && 'white',
        fill: !theme.isDark && 'white',
      },
    },
    menuItemText: {
      // required for menuItem focus
      color: theme.palette.common.white,
    },
    routeParent: {},
    currentRouteMain: {
      background: fade(theme.palette.primary.main, 0.2),

      '& .MuiListItemIcon-root, & $menuItemText, & .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
    currentRouteSub: {
      borderRight: `6px solid ${fade(theme.palette.primary.main, 0.8)}`,

      '& .MuiListItemIcon-root, & $menuItemText': {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
      },
    },
    currentRouteParent: {
      //
      background: fade(theme.palette.primary.main, 0.2),
      '& .MuiListItemIcon-root, & $menuItemText, & .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },

    bottomMenu: {
      '& .MuiListItem-root': {
        paddingLeft: theme.spacing(0.5)
      }
    }
  }
})

const Sidebar = ({ route, isMobile, routeParams, tabsConfigParams, sidebarIsOpen, onClose }) => {
  const classes = useStyles()
	const { loggedInFaction } = useContext(CadFactionContext)
  const { jobsWithService } = useContext(PublicVariablesContext)
  const { userMe } = useContext(UserMeContext)
  const settings = useContext(SettingsContext)

  const tabsConfig = createTabsConfigCad(loggedInFaction, jobsWithService, settings, userMe)

  if (!sidebarIsOpen && isMobile) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.leftArea}>
        <div className={classes.logoArea}>
          {!isMobile && (
            <Link to="DASHBOARD">
              <LogoIcon width={'auto'} height={42} fill="white" className={classes.logo} />
            </Link>
          )}
          <Link to="DASHBOARD">
            <TypographyCustom type="184" className={classes.brand} variant="headline">
              TASEv3
            </TypographyCustom>
          </Link>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={onClose}>
              <ChevronLeftIcon className={classes.drawerIcon} />
            </IconButton>
          </div>
        </div>
      </div>

      <MenuList
        className={clsx(classes.menuList, { [classes.menuListSidebarClosed]: !sidebarIsOpen })}
      >
        <Item
          route="DASHBOARD"
          currentRoute={route}
          classes={classes}
          icon={<HomeIcon />}
          text="Accueil"
          onClose={onClose}
          isMobile={isMobile}
        />

        {SecurityManager.hasPermission("api.playerList") && (
          <Item
            route="RCON"
            currentRoute={route}
            classes={classes}
            icon={<PeopleIcon />}
            text="Joueurs"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {SecurityManager.hasPermission("api.playerList.canReply") && (
          <Item
            route="REPORTS"
            currentRoute={route}
            classes={classes}
            icon={<ChatIcon />}
            text="Reports"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {SecurityManager.hasPermission("api.ticket") && (
          <Item
            route="TICKET"
            currentRoute={route}
            classes={classes}
            icon={<ConfirmationNumberIcon />}
            text="Tickets"
            onClose={onClose}
            subRoutesAsSubMenu
            isMobile={isMobile}
            routes={[
              "TICKET",
              "TICKET_HISTORY",
              "TICKET_BLACKLIST",
            ]}
          >
            <Item
              route="TICKET"
              currentRoute={route}
              classes={classes}
              icon={<ConfirmationNumberIcon />}
              text="Tickets"
              onClose={onClose}
              isMobile={isMobile}
            />
            <Item
              route="TICKET_HISTORY"
              currentRoute={route}
              classes={classes}
              icon={<AccessTimeIcon />}
              text="Mes 20 derniers tickets"
              onClose={onClose}
              isMobile={isMobile}
            />
            <Item
              route="TICKET_BLACKLIST"
              currentRoute={route}
              classes={classes}
              icon={<BanlistIcon />}
              text="Blacklist"
              onClose={onClose}
              isMobile={isMobile}
            />
          </Item>
        )}

        {SecurityManager.hasPermission("api.gestion") && (
          <Item
            text="Gestion"
            route="FACTION"
            currentRoute={route}
            classes={classes}
            icon={<DashboardIcon />}
            subRoutesAsSubMenu
            tabs={getTabsConfigManagement({ routeParams, tabsConfigParams }).tabs}

            onClose={onClose}
            isMobile={isMobile}
          />
        )}
      
        {SecurityManager.hasPermission("api.administration") && (
          <Item
            text="Administration"
            route="ADMIN_LIST"
            currentRoute={route}
            classes={classes}
            icon={<SettingsIcon />}
            subRoutesAsSubMenu
            tabs={getTabsConfigAdministration().tabs}
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {SecurityManager.hasPermission("api.logs") && (
          <Item
            route="LOGS"
            currentRoute={route}
            classes={classes}
            icon={<LogsIcon />}
            text="Logs"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {__DEV__ && (
          <Item
            route="LIVE_CONSOLE"
            currentRoute={route}
            classes={classes}
            icon={<ConsoleIcon />}
            text="Console"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {SecurityManager.hasPermission("api.banlist") && (
          <Item
            route="BANLIST"
            currentRoute={route}
            classes={classes}
            icon={<BanlistIcon />}
            text="Banlist"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {SecurityManager.hasPermission("api.darkChat") && (
          <Item
            route="DARKCHAT"
            currentRoute={route}
            classes={classes}
            icon={<DarkChatIcon />}
            text="DarkChat"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {SecurityManager.hasPermission("api.siem") && (
          <Item
            route="SIEM"
            currentRoute={route}
            classes={classes}
            icon={<SIEMIcon />}
            text="SIEM"
            onClose={onClose}
            isMobile={isMobile}
          />
        )}
        
        {SecurityManager.hasPermission("api.ranking") && (
          <Item
            route="RANKING_MONEY"
            currentRoute={route}
            classes={classes}
            icon={<RankingIcon />}
            text="Tops"
            matchRoutes={/\/ranking\.*/}
            subRoutesAsSubMenu
            tabs={getTabsConfigRanking().tabs}
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {__DEV__ && (
          <Item 
            route="MAP" 
            currentRoute={route} 
            classes={classes} 
            icon={<MapIcon />} 
            text="Map"     
            onClose={onClose}
            isMobile={isMobile}
          />
        )}

        {(SecurityManager.hasPermission("api.cad") || SecurityManager.hasPermission("cad")) && (
          <Item
            route="CAD_DASHBOARD"
            currentRoute={route}
            classes={classes}
            icon={<CadIcon />}
            text="CAD"
            matchRoutes={/\/cad\.*/}
            subRoutesAsSubMenu
            tabs={tabsConfig().tabs}
            onClose={onClose}
            isMobile={isMobile}
          />
        )}
          
      </MenuList>

      <MenuList className={classes.bottomMenu}>
        {isMobile && (
          <Button onClick={onClose} fullWidth>Fermer</Button>
        )}
      </MenuList>
    </React.Fragment>
  )
}

export default Sidebar
