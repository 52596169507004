import { useEffect, useState } from 'react';
import { getHistory } from 'reacticoon/routing'

function useBlockHistory(onBlock) {
  const history = getHistory()
  const [nextLocation, setNextLocation] = useState(null)
  const [forcePush, setForcePush] = useState(false)

  useEffect(() => {
    return history.block(location => {
      const shouldBlock = onBlock()
      if (shouldBlock) {
        setNextLocation(location)
        return false
      }
      return true
    })
  }, [onBlock, history ])

  useEffect(() => {
    if (forcePush) {
      history.push(nextLocation)
    }
  }, [forcePush, nextLocation, history])

  return {
    nextLocation,
    wantsToMove: !!nextLocation,
    allowMove: () => setForcePush(true),
    cancelMove: () => setNextLocation(null),
  }
}

export default useBlockHistory