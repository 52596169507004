import AuthModule from 'modules/auth'
import LoginModule from 'modules/login'
import RegisterModule from 'modules/register'
import CommandModule from 'modules/command'
import SearchModule from 'modules/search'
import UserMeModule from 'modules/userMe'
import NotificationsModule from "modules/notification"

export default [
  AuthModule,
  CommandModule,
  LoginModule,
  RegisterModule,
  UserMeModule,
  SearchModule,
  NotificationsModule,
]
