import React, { createElement } from "react";
import ReactDOM from "react-dom";
import { useSelector } from 'react-redux'

import useEscapeEffect from "../useEscapeEffect"
import useBlockHistory from "../useBlockHistory"
import PublicVariablesContext from 'modules/publicVariables/context'
import { fetchPublicVariablesListSelectors } from 'modules/publicVariables/selectors'

const ModalCustom = ({ modalConfiguration, onClose }) => {
	const { view, ...otherProps } = modalConfiguration
	return createElement(view, { onClose, ...otherProps })
}

function ModalContent({ onClose, children }) {
	const publicVariables = useSelector(fetchPublicVariablesListSelectors.getData)

	useBlockHistory(() => {
		onClose()
		return false
	})

	useEscapeEffect(() => onClose())

	return (
		<PublicVariablesContext.Provider value={publicVariables}>
			{publicVariables ? children : <div />}
		</PublicVariablesContext.Provider>
	)
}

function Modal({ modalConfiguration, show, onClose }) {
	if (modalConfiguration && show) {
		const modal = {
			'CUSTOM': ModalCustom
		}

		const modalView = modal[modalConfiguration.type]

		if (!modalView) {
			throw new Error(`type not handled ${modalConfiguration.type}`)
		}

		return ReactDOM.createPortal(
			<ModalContent onClose={onClose}>
				{createElement(modalView, { modalConfiguration, onClose })}
			</ModalContent>,
			document.querySelector("#modal-root")
		)
	}

	return null;
}

export default Modal;