import React from "react"
import { withStyles } from '@material-ui/core/styles'
import WebsocketStatusView from "components/WebsocketStatusView"
import useWebsocket from 'modules/websocket'
import OptionalTooltip from "components/OptionalTooltip"

const styles = theme => ({
  root: {
		color: theme.status.warning,
  },
})

function WebsocketLoading({ classes }) {
  const { loggedIn, readyState } = useWebsocket()
  return (
    <OptionalTooltip title="Connexion au temps réel en cours">
      <WebsocketStatusView
        className={classes.root}
        variant="loading"
        loggedIn={loggedIn}
        readyState={readyState}
        // trick, we do not target a specific subscription here, since it is a global status view
        subscribed
        size={20}
      />
    </OptionalTooltip>
  )
}

export default withStyles(styles)(WebsocketLoading)