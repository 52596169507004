import React from 'react'

import isEmpty from 'lodash/isEmpty'
import Tooltip from '@material-ui/core/Tooltip'
import TypographyCustom from 'components/TypographyCustom'

const OptionalTooltip = ({ children, title, show = true }) =>
  show && !isEmpty(title) ? (
    <Tooltip
      title={
        <TypographyCustom
          type="164"
          style={{ color: 'white', lineHeight: '22px', textAlign: 'center' }}
        >
          {title}
        </TypographyCustom>
      }
    >
      {/* By default disabled elements like <button> do not trigger user interactions so a Tooltip 
        will not activate on normal events like hover. To accommodate disabled elements, add a simple
         wrapper element like a span.
         https://material-ui.com/components/tooltips/
 */}
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )

export default OptionalTooltip
