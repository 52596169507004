import { createSimpleContainer } from 'reacticoon/container'

import PublicVariablesModule from 'modules/publicVariables'
import PublicVariablesContext from './context'

const PublicVariablesListContainer = createSimpleContainer(
  'App::PublicVariables::PublicVariablesListContainer',
  {
    module: PublicVariablesModule,
    apiCallAction: 'fetchPublicVariablesList',
    context: PublicVariablesContext
  }
)

export default PublicVariablesListContainer
