import { createApiCallAction } from 'reacticoon/action'

//
// Actions
//

export const postLogin = createApiCallAction(
  'App::LoginModule::login',
  (username, password, recaptchaResponse) => ({
    type: 'POST',
    endpoint: '/commands/AUTH_LOGIN',
    body: {
      command: 'AUTH_LOGIN',
      payload: {
        username,
        password,
        recaptchaResponse,
      },
    },
    /*response: {
    token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MCwiaWF0IjoxNTkzMTkwODk3fQ.GBbPQ-OSovkH-Lcu0R2fFBcrBUTxNDtGBRa6W8dB2kQ
  },*/
  })
)
